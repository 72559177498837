import { Map, AddAlert, AllInclusive,  } from '@material-ui/icons'
import App from '../App'

import ClientBoundariesContainer from '../containers/ClientBoundariesContainer'
import LoginView from '../views/LoginView'

const Routes = [
    {
        path: '*',
        name: '404',
        component: ClientBoundariesContainer,
        hide: true
    }, {
        path: '/',
        name: 'Boundaries',
        icon: Map,
        component: ClientBoundariesContainer,
    }
]

export default Routes
