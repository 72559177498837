import axios from 'axios'
import Utils from '../utils/Utils'

let DataController = {}

DataController.GetSubscriptions = async (userToken) => {
    try {
        let response = await axios.post(`${process.env.REACT_APP_28EAST_API_URL}/getsubscriptions`,
            {
                token: userToken,
                channel: 'boundaries map',
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })

        return response.data
    } catch (err) {
        console.error(err)
        return err
    }
}
DataController.ParseFeatureCollection = (featureCollection) => {
    let features = []

    featureCollection.features.forEach(feature => {
        feature.properties.id = feature.properties.client_boundary_id
        feature.properties.type = feature.geometry.type.toLowerCase()
        feature.properties.details = feature.ui_config
        if (!feature.properties.details) {
            feature.properties.details = {}
        }
        if (!feature.properties.details.name && feature.properties.name) feature.properties.details.name = { value: feature.properties.name }
        if (!feature.properties.details.description && feature.properties.description) feature.properties.details.description = { value: feature.attributes.description }
        if (!feature.properties.details.type && feature.properties.type) feature.properties.details.type = { value: { value: feature.properties.type } }
        feature.properties.details.active = { value: !!feature.properties.active }
        features.push(feature)
    })

    return features
}

DataController.ParseSubscriptions = (service_subscriptions) => {
    if (!service_subscriptions) return

    let services = [], providers = [], statuses = [], subscription = service_subscriptions, clientName = null

    Object.keys(service_subscriptions).forEach(ss => {
        let sub = service_subscriptions[ss]
        Object.keys(sub).forEach(p => {
            let prov = sub[p]
            if (!clientName) clientName = prov.client_name
            services.push({ title: prov.service_full_name, value: prov.service_name })
            providers.push({ title: prov.provider_full_name, value: prov.provider_name })
            prov.statuses.forEach((s, i) => {
                statuses.push({
                    title: prov.status_full_names && prov.status_full_names.length > i ? prov.status_full_names[i] : s,
                    value: s
                })
            })

        })
    })

    return {
        loading: false,
        clientName: clientName,
        services: services.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i),
        providers: providers.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i),
        statuses: statuses.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i),
        subscription
    }

}

DataController.ToAPIFeature = (feature) => {
    if (!feature) return
    if (!feature.properties || !feature.properties.details) return feature

    let coverageFilter = []

    if (feature.properties.details.services?.value && feature.properties.details.services?.value.length > 0) {
        feature.properties.details.services.value.forEach(s => {
            coverageFilter.push({
                type: s.value,
            })
        })
    }
    if (coverageFilter.length > 0 && feature.properties.details.providers && feature.properties.details.providers.value && feature.properties.details.providers.value.length > 0) {
        feature.properties.details.providers.value.forEach(provider => {
            coverageFilter.forEach(f => {
                if (!f.providers) f.providers = []
                f.providers.push({
                    provider: provider.value
                })
            })
        })
    }
    if (coverageFilter.length > 0 && feature.properties.details.statuses && feature.properties.details.statuses.value && feature.properties.details.statuses.value.length > 0) {
        //This block is for individual provider status
        let newFilter = []
        feature.properties.details.statuses.value.forEach(status => {
            coverageFilter.forEach((f, i) => {
                if (f.providers && f.providers.length > 0) {
                    f.providers.forEach(p => {
                        if (!p.status) {
                            p.status = status.value
                            newFilter.push(p)
                        } else {
                            newFilter.push({
                                ...p,
                                status: status.value
                            })
                        }
                    })
                }
                // else {
                //     newFilter.push(f)
                // }
            })
        })
        coverageFilter.forEach(s => {
            s.providers = newFilter.filter((v, i, a) => a.findIndex(t => (t.provider === v.provider && t.status === v.status)) === i)
        })
        // coverageFilter = [...newFilter]

        //This block is for status array
        // let statuses = []
        // feature.properties.details.statuses.value.forEach(status => {
        //     statuses.push(status.value)
        // })
        // coverageFilter.forEach(f => {
        //     if (f.providers && f.providers.length > 0) {
        //         f.providers.forEach(p => {
        //             p.status = statuses
        //         })
        //     }
        // })
    }

    // let attributes = {}
    // Object.keys(feature.properties.details).forEach(key => {
    //     let d = feature.properties.details[key]
    //     if (!feature[d.grouping]) feature[d.grouping] = {}
    //     if (key === 'name') {
    //         feature['properties'][key] = d.value
    //     }
    //     feature[d.grouping][key] = d.value
    // })

    let newFeature = {
        type: feature.type,
        geometry: feature.geometry,
        properties: {
            active: feature.properties.details.active.value,
            name: feature.properties.details.name.value,
            // category: feature.properties.details.value ?? 'feature',
            boundary_type: feature.properties.details.type.value,
        },
        attributes: {
            // name: feature.properties.details.name.value,
            description: feature.properties.details.description?.value
        },
        style: feature.style,
        ui_config: feature.properties.details,
        coverage_filter: coverageFilter
    }
    if (newFeature.attributes?.description?.replaceAll(' ', '') === '') delete newFeature.attributes.description
    if (feature.properties && feature.properties.id) newFeature.properties.id = feature.properties.id
    if (feature.properties && feature.properties.details && feature.properties.details.category) newFeature.properties.category = feature.properties.details.category.value
    if (feature?.properties?.details?.attributes?.value?.length > 0) newFeature.attributes = { ...newFeature.attributes, ...JSON.parse(feature?.properties?.details?.attributes.value) }
    return newFeature
}


DataController.GetClientCategories = async (userToken) => {
    try {
        let clientName = parseUserToken(userToken)
        let response = await axios.get(`${process.env.REACT_APP_28EAST_API_URL}/internal/boundaries/client/${clientName}/categories?channel=${Utils.uuid()}`)

        return response.data
    } catch (err) {
        console.error(err)
        return {
            success: false,
            code: err.code ? err.code : 500,
            message: err.code ? err.message : 'Internal Server Error'
        }
    }
}

DataController.GetClientBoundaries = async (userToken) => {
    try {
        let clientName = parseUserToken(userToken)
        let response = await axios.get(`${process.env.REACT_APP_28EAST_API_URL}/internal/boundaries/client/${clientName}?channel=${Utils.uuid()}`)

        if (response.data && response.data.success && response.data.data)
            return DataController.ParseFeatureCollection(response.data.data)
        else if (response.data && !response.data.success)
            throw new Error({ code: response.data.code, message: response.data.message })
        return []
    } catch (err) {
        console.error(err)
        return {
            success: false,
            code: err.code ? err.code : 500,
            message: err.code ? err.message : 'Internal Server Error'
        }
    }
}

DataController.AddNewFeatures = async (userToken, data) => {
    try {
        let clientName = parseUserToken(userToken)
        if (!data || !data.type) throw new Error({ code: 400, message: 'Bad Request - Invalid or no data' })
        if (data.type !== 'Feature' && data.type !== 'FeatureCollection') throw new Error({ code: 400, message: 'Bad Request - Invalid feature' })

        // let featureCollection = data
        // if (data.type === 'Feature') {
        //     featureCollection = {
        //         featureCollection: {
        //             type: 'FeatureCollection',
        //             features: [data]
        //         }
        //     }
        // }
        let params = {
            featureCollection: data
        }
        if (data.type === 'Feature') {
            params.featureCollection = {
                type: 'FeatureCollection',
                features: [data]
            }
        }

        console.log('!!!!!! featureCollection', params.featureCollection)

        let response = await axios.put(`${process.env.REACT_APP_28EAST_API_URL}/internal/boundaries/client/${clientName}?channel=${Utils.uuid()}`,
            params,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

        return response.data
    } catch (err) {
        console.error(err)
        return {
            success: false,
            code: err.code ? err.code : 500,
            message: err.code ? err.message : 'Internal Server Error'
        }
    }
}


DataController.UpdateFeatures = async (userToken, data) => {
    try {
        let clientName = parseUserToken(userToken)
        if (!data) throw new Error({ code: 400, message: 'Bad Request - Invalid or no data' })
        if (!data.properties || !data.properties.id) throw new Error({ code: 400, message: 'Bad Request - Invalid or no id' })
        if (data.type !== 'Feature' && data.type !== 'FeatureCollection') throw new Error({ code: 400, message: 'Bad Request - Invalid feature' })

        // let featureCollection = data
        // if (data.type === 'Feature') {
        //     featureCollection = {
        //         featureCollection: {
        //             type: 'FeatureCollection',
        //             features: [data]
        //         }
        //     }
        // }
        let params = {
            featureCollection: data
        }
        if (data.type === 'Feature') {
            params.featureCollection = {
                type: 'FeatureCollection',
                features: [data]
            }
        }

        let response = await axios.patch(`${process.env.REACT_APP_28EAST_API_URL}/internal/boundaries/client/${clientName}?channel=${Utils.uuid()}`,
            params,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

        return response.data
    } catch (err) {
        console.error(err)
        return {
            success: false,
            code: err.code ? err.code : 500,
            message: err.code ? err.message : 'Internal Server Error'
        }
    }
}

DataController.DeleteFeature = async (userToken, data) => {
    try {
        let clientName = parseUserToken(userToken)
        if (!data) throw new Error({ code: 400, message: 'Bad Request - Invalid or no data' })
        if (!data.id) throw new Error({ code: 400, message: 'Bad Request - Invalid or no id' })
        console.log('url', process.env.REACT_APP_28EAST_API_URL)
        let response = await axios.delete(`${process.env.REACT_APP_28EAST_API_URL}/internal/boundaries/client/${clientName}/${data.id}?channel=${Utils.uuid()}`)

        return response.data
    } catch (err) {
        console.error(err)
        return {
            success: false,
            code: err.code ? err.code : 500,
            message: err.code ? err.message : 'Internal Server Error'
        }
    }
}

DataController.MigrateBoundaries = async (userToken, config, data) => {
    try {
        if (!config || (!config.source || !config.source.url) || (!config.destination || !config.destination.url)) throw new Error({ code: 400, message: 'Bad Request - Invalid or no data' })

        let clientName = parseUserToken(userToken)

        let params = {
            featureCollection: data
        }
        if (data.type === 'Feature') {
            params.featureCollection = {
                type: 'FeatureCollection',
                features: [data]
            }
        }
        let response = await axios.post(`${config.destination.url}/internal/boundaries/client/${clientName}/migrate?channel=${Utils.uuid()}`,
            params,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

        return response.data
    } catch (err) {
        console.error(err)
        return {
            success: false,
            code: err.code ? err.code : 500,
            message: err.code ? err.message : 'Internal Server Error'
        }
    }
}

DataController.RevertMigrateBoundaries = async (userToken, destination) => {
    try {
        if (!destination || !destination.url) throw new Error({ code: 400, message: 'Bad Request - Invalid or no data' })

        let clientName = parseUserToken(userToken)
        let response = await axios.post(`${destination.url}/internal/boundaries/client/${clientName}/restore?channel=${Utils.uuid()}`)

        return response.data
    } catch (err) {
        console.error(err)
        return {
            success: false,
            code: err.code ? err.code : 500,
            message: err.code ? err.message : 'Internal Server Error'
        }
    }
}

function parseUserToken(userToken) {
    if (!userToken || userToken.length === 0) throw new Error({ code: 400, message: 'Bad request - Invalid userToken' })
    let tokenArr = userToken.split('-')
    if (!tokenArr || tokenArr.length === 0) throw new Error({ code: 400, message: 'Bad request - Invalid userToken' })

    return tokenArr[0]
}

export default DataController
