import React, { useContext, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { createPortal } from 'react-dom';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types'; // ES6

const useStyles = makeStyles((theme) => ({
    root: {
    }
}))

function OverlayViewContainer(props) {
    const classes = useStyles()
    let el = null
    let overlay = null

    class CustomOverlayView extends window.google.maps.OverlayView {
        /*eslint no-var: "error"*/
        /*eslint-env es6*/
        position = null;
        content = null;

        constructor(p) {
            super(p);
            console.log('### constructor, p ', p)
            p.position && (this.position = p.position);
            p.content ? (this.content = p.content) : this.content = document.getElementById("content");
        }

        /** Called when the popup is added to the map. */
        onAdd = () => {
            if (this.content)
                this.getPanes().floatPane.appendChild(this.content);
        };

        /** Called when the popup is removed from the map. */
        onRemove = () => {
            if (this.content.parentElement) {
                this.content.parentElement.removeChild(this.content);
            }
        };

        /** Called each frame when the popup needs to draw itself. */
        draw = () => {
            console.log('### posi', this.position)
            const divPosition = this.getProjection().fromLatLngToDivPixel(
                this.position
            );
            console.log("### content", this.content)
            if (this.content) {
                this.content.style.left = divPosition.x + 'px';
                this.content.style.top = divPosition.y + 'px';
            }
        };
    }

    useEffect(() => {
        console.log('### props', props)
        if (!props.map) return
        el = el || document.createElement('div');
        overlay = overlay ||
            new CustomOverlayView({
                position: props.position,
                content: el
            });
        overlay.setMap(props.map);
    }, [])

    return (
        <div className={classes.root}>
            {props.map&&
                ReactDOM.createPortal(props.children, el)
            }
        </div>
    )
}

OverlayViewContainer.propTypes = {
    map: PropTypes.object,
    position: PropTypes.object
}

function MarkerContainer(props) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <OverlayViewContainer position={props.position} map={props.map}>
                {props.children}
            </OverlayViewContainer>
        </div>
    )
}


MarkerContainer.propTypes = {
    map: PropTypes.object,
    position: PropTypes.object
}

export default MarkerContainer