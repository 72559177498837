import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { BrandContext } from '../../context/BrandContext/context'


export default function Loader({ message }) {
  const { brand } = useContext(BrandContext)

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      width: '100%',
    },
    toolbar: {
      paddingRight: 24 // keep right padding when drawer closed
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: '100%',
    },
    fullHeightContainer: {
      padding: theme.spacing(0),
      maxWidth: '100%',
      height: brand.navbar.show && brand.navbar.position === 'fixed' ? 'calc(100% - 64px)' : '100%',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    loaderImage: {
      margin: '0px 0px 0px 24px',
      width: '55px',
    },
    loaderContainer: {
      position: 'fixed',
      width: '143px',
      // height: '38px',
      zIndex: '9999',
      top: 'calc(50%)',
      left: 'calc(50% - 72px)',
      webkitTouchCallout: 'none',
      webkitUserSelect: 'none',
      khtmlUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      OUserSelect: 'none',
      userSelect: 'none',
      animation: '$myEffect 1000ms infinite'
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      }
    }
  }))

  const classes = useStyles()
  const loadImage = './loader.gif'


  return (
    <div className={classes.loaderContainer}>
      <div className='loader' />
      {loadImage && <img className={classes.loaderImage} src={loadImage} />}
      {/* <span className='loadingText'>{message ? message : 'Loading...'}</span> */}
    </div>
  )
}
