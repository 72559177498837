import React, { useContext, useEffect, useState } from 'react'

import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Input, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons'

import Utils from '../../utils/Utils'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'absolute',
        zIndex: '1'
    },
    contentRoot: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}))

function property(props) {
    const classes = useStyles()
    const prop = props.data
    const [parents, setParents] = useState([])

    useEffect(() => {
        if (props.parents) setParents(props.parents)
    }, [])

    if (prop.lookup && !props.multiple)
        return <FormControl key={key + '_lookup'} className={classes.formControl}>
            <InputLabel
                id={key + '_lookup_lbl'}
                key={key + '_lookup_lbl'}>
                {prop.title}
            </InputLabel>
            <Select
                labelId={key + '_lookup_select'}
                id={key + '_lookup_select'}
                key={key + '_lookup'}
                value={prop.value}
                onChange={(e) => {
                    if (props.handleChange) handleChange(key, prop)
                }}
            >
                {prop.lookup.map(l => {
                    return <MenuItem key={key + '_lookup' + '_' + l.value} value={length.value}>{l.title}</MenuItem>
                })}
            </Select>
        </FormControl>
    else if (prop.lookup && props.multiple)
        return <FormControl key={key + '_lookup'} className={classes.formControl}>
            <InputLabel
                id={key + '_lookup_lbl'}
                key={key + '_lookup_lbl'}>
                {prop.title}
            </InputLabel>
            <Select
                labelId={key + '_lookup_select'}
                id={key + '_lookup_select'}
                key={key + '_lookup'}
                multiple
                fullWidth
                value={prop.value}
                onChange={(e) => {
                    if (props.handleChange) handleChange(key, prop)
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                    </div>
                )}
            >
                {prop.lookup.map(l => {
                    return <MenuItem key={key + '_lookup' + '_' + l.value} value={length.value}>{l.title}</MenuItem>
                })}
            </Select>
        </FormControl>
    else if (prop.type === 'String')
        return <TextField
            key={key + '_txt'}
            autoFocus
            margin="dense"
            id={key + "_txt"}
            label={prop.title}
            type="text"
            fullWidth
            value={prop.value}
            onChange={(e) => {
                if (props.handleChange) handleChange(key, prop)
            }}
        />
    else if (prop.type === 'Number')
        return <TextField
            autoFocus
            key={key + '_nbr'}
            margin="dense"
            id={key + "_txt"}
            label={prop.title}
            type="number"
            fullWidth
            value={prop.value}
            onChange={(e) => {
                if (props.handleChange) handleChange(key, prop)
            }}
        />
    else if (prop.type === 'Object' && prop.value)
        return <property data={prop.value} handleChange={props.handleChange} parents={[key, parents]} />
    else
        return <></>

}

function Content(props) {
    const classes = useStyles()
    const [details, setDetails] = useState({})
    const [lookups, setLookups] = useState([])

    useEffect(() => {

        if (props.details) setDetails(props.details)
        else if (props.schema) setDetails(parseSchemaToObject(props.schema))

    }, [])

    const parseSchemaToObject = (schema) => {
        let tmpDetails = {}
        for (let key in schema) {
            let obj = schema[key]
            if (obj.lookup) tmpDetails[key] = obj.value ? obj.value : []
            else if (obj.type === 'String') tmpDetails[key] = obj.value ? obj.value : ''
            else if (obj.type === 'Number') tmpDetails[key] = obj.value ? obj.value : 0
            else if (obj.type === 'Object') {
                if (obj.value && obj.value !== {})
                    tmpDetails[key] = parseSchemaToObject(obj.value)
                else tmpDetails[key] = {}
            }
        }
        return tmpDetails
    }

    const handleClose = () => {
        if (props.handleClose) props.handleClose()
    }
    const handleSave = () => {
        if (props.handleSave) props.handleSave(details)
    }

    const handleLookupSelect = (e, key) => {
        console.log('e', e)
        console.log('key', key)
    }

    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{'Feature'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    key={'title'}
                    margin="dense"
                    id={"title_txt"}
                    label={'Title'}
                    fullWidth
                />
                <TextField
                    key={'description'}
                    margin="dense"
                    id={"description_txt"}
                    label={'Description'}
                    type="string"
                    fullWidth
                />
                <FormControl key={'boundary_type_lookup'} className={classes.formControl}>
                    <InputLabel
                        id={'boundary_type_lookup_lbl'}
                        key={'boundary_type_lookup_lbl'}>
                        {'Boundary Type'}
                    </InputLabel>
                    <Select
                        labelId={'boundary_type_lookup_select'}
                        id={'boundary_type_lookup_select'}
                        key={'boundary_type_lookup'}
                        value={lookups['boundary_type']}
                        onChange={(e) => {
                            handleLookupSelect(e, 'boundary_type')
                        }}
                    >
                        {props.boundaryTypes.map(l => {
                            return <MenuItem key={'boundary_type_lookup' + '_' + l.value} value={length.value}>{l.title}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl key={'services_lookup'} className={classes.formControl}>
                    <InputLabel
                        id={'services_lookup_lbl'}
                        key={'services_lookup_lbl'}>
                        {'Services'}
                    </InputLabel>
                    <Select
                        labelId={'services_lookup_select'}
                        id={'services_lookup_select'}
                        key={'services_lookup'}
                        multiple
                        fullWidth
                        // value={lookups['services']}
                        onChange={(e) => {
                            handleLookupSelect(e, 'services')
                        }}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                    >
                        {props.services&& props.services.map(l => {
                            return <MenuItem key={'services_lookup' + '_' + l.value} value={l.value}>{l.title}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                {/* <FormControl key={'providers_lookup'} className={classes.formControl}>
                    <InputLabel
                        id={'providers_lookup_lbl'}
                        key={'providers_lookup_lbl'}>
                        {'Providers'}
                    </InputLabel>
                    <Select
                        labelId={'providers_lookup_select'}
                        id={'providers_lookup_select'}
                        key={'providers_lookup'}
                        multiple
                        fullWidth
                        value={lookups['providers']}
                        onChange={(e) => {
                            handleLookupSelect(e, 'providers')
                        }}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                    >
                        {props.providers&& props.providers.map(l => {
                            return <MenuItem key={'providers_lookup' + '_' + l.value} value={length.value}>{l.title}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl key={'statuses_lookup'} className={classes.formControl}>
                    <InputLabel
                        id={'statuses_lookup_lbl'}
                        key={'statuses_lookup_lbl'}>
                        {'Statuses'}
                    </InputLabel>
                    <Select
                        labelId={'statuses_lookup_select'}
                        id={'statuses_lookup_select'}
                        key={'statuses_lookup'}
                        multiple
                        fullWidth
                        value={lookups['statuses']}
                        onChange={(e) => {
                            handleLookupSelect(e, 'statuses')
                        }}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                    >
                        {props.statuses && props.statuses.map(l => {
                            return <MenuItem key={'statuses_lookup' + '_' + l.value} value={length.value}>{l.title}</MenuItem>
                        })}
                    </Select>
                </FormControl> */}

                {/* {props.schema ?
                    Object.keys(props.schema).map(key => {
                        let prop = props.schema[key]
                        if (prop.lookup && !props.multiple)
                            return <FormControl key={key + '_lookup'} className={classes.formControl}>
                                <InputLabel
                                    id={key + '_lookup_lbl'}
                                    key={key + '_lookup_lbl'}>
                                    {prop.title}
                                </InputLabel>
                                <Select
                                    labelId={key + '_lookup_select'}
                                    id={key + '_lookup_select'}
                                    key={key + '_lookup'}
                                    value={lookups[key]}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        handleLookupSelect(e, key, prop)
                                    }}
                                >
                                    {prop.lookup.map(l => {
                                        return <MenuItem key={key + '_lookup' + '_' + l.value} value={length.value}>{l.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        else if (prop.lookup && props.multiple)
                            return <FormControl key={key + '_lookup'} className={classes.formControl}>
                                <InputLabel
                                    id={key + '_lookup_lbl'}
                                    key={key + '_lookup_lbl'}>
                                    {prop.title}
                                </InputLabel>
                                <Select
                                    labelId={key + '_lookup_select'}
                                    id={key + '_lookup_select'}
                                    key={key + '_lookup'}
                                    multiple
                                    fullWidth
                                    value={lookups[key]}
                                    onChange={(e) => {
                                        handleLookupSelect(e, key, prop)
                                    }}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={classes.chip} />
                                            ))}
                                        </div>
                                    )}
                                // MenuProps={MenuProps}
                                >
                                    {prop.lookup.map(l => {
                                        return <MenuItem key={key + '_lookup' + '_' + l.value} value={length.value}>{l.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        else if (prop.type === 'String')
                            return <TextField
                                key={key + '_txt'}
                                autoFocus
                                margin="dense"
                                id={key + "_txt"}
                                label={prop.title}
                                type="text"
                                fullWidth
                            />
                        else if (prop.type === 'Number')
                            return <TextField
                                autoFocus
                                key={key + '_nbr'}
                                margin="dense"
                                id={key + "_txt"}
                                label={prop.title}
                                type="number"
                                fullWidth
                            />
                        else if (prop.type === 'Object')
                            return <></>
                        else
                            return <></>
                    })
                    : <Typography>No information to display</Typography>} */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default function BasicBoundaryFormDialog(props) {
    const classes = useStyles()
    console.log(props.services)

    return (
        <div className={classes.root}>
            <Content
                schema={props.schema}
                details={props.details}
                boundaryTypes={props.boundaryTypes}
                services={props.services}
                providers={props.providers}
                statuses={props.statuses}
                open={props.open}
                handleClose={props.handleClose}
                handleSave={props.handleSave} />
        </div>
    )
}
