import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PaletteIcon from '@material-ui/icons/Palette';
import { CircularProgress, Divider, ListItemIcon, Paper, TextField } from '@material-ui/core';
import { ReactComponent as PolygonIcon } from '../../content/polygonFill.svg'
import { ReactComponent as SquareIcon } from '../../content/squareFill.svg'
import { ReactComponent as CircleIcon } from '../../content/circleFill.svg'
import { SvgIcon } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { GithubPicker } from 'react-color';
import FeaturesListItem from './FeaturesListItem';
import { Close, Filter, FilterList, Person, Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { UserContext } from '../../context/UserContext/context';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: theme.spacing(1),
        height: '80vh'
    },
    title: {
        margin: theme.spacing(1),
        marginBottom: '0px',
        display: 'flex',
    },
    noItems: {
        textAlign: 'center',
        color: '#989898',
        padding: theme.spacing(2)
    },
    filter: {
        margin: '0px',
        padding: '0px',
        paddingLeft: theme.spacing(1),
    },
    filterIcon: {
        padding: theme.spacing(1)
    },
    grow: {
        flexGrow: 1
    },
    features: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '88%'
    },
    center: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export default function FeaturesList(props) {
    const classes = useStyles();
    const { user } = useContext(UserContext)
    const [showFilter, setShowFilter] = useState(false)
    const [showClients, setShowClients] = useState(false)
    const [filter, setFilter] = useState('')
    const [details, setDetails] = useState(props.details ? props.details : [])

    const updateDetailsFilter = () => {
        let tmp = []
        if (filter && filter.length > 0) {
            tmp = props.details.filter(d => d.properties.details.title.value.includes(filter))
        } else {
            tmp = props.details
        }
        setDetails(tmp)
    }

    const onSelect = (value) => {
        if (props.onSelect) props.onSelect(value)
    }

    const onEdit = (value) => {
        if (props.onEdit) props.onEdit(value)
    }

    const onDelete = (value) => {
        if (props.onDelete) props.onDelete(value)
    }

    const onZoom = (value) => {
        if (props.onZoom) props.onZoom(value)
    }

    const onSave = (value) => {
        if (props.onSave) props.onSave(value)
    }

    const onCancel = (value) => {
        if (props.onCancel) props.onCancel(value)
    }

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    }

    return (
        <Paper className={classes.root} elevation={2}>
            {/* <div className={classes.container}> */}
            <div className={classes.title}>
                <Typography variant="h6" className={classes.grow}>
                    {props.title ? props.title : 'Features'}
                </Typography>
                {showFilter &&
                    <TextField
                        color="default"
                        className={classes.filter}
                        value={filter}
                        onChange={handleFilterChange}
                        key={'filter_txt'}
                        margin="dense"
                        id={"filter_txt"}
                        // label={'Filter'}
                        placeholder={'Filter Features'}
                        type="text"
                        fullWidth
                    />
                }
                <IconButton className={classes.filterIcon}>
                    {!showFilter ?
                        <FilterList onClick={() => {
                            setShowFilter(true)
                            setShowClients(false)
                        }} />
                        : <Close onClick={() => {
                            setShowFilter(false)
                            setShowClients(false)
                            setFilter('')
                        }} />}
                </IconButton>
            </div>
            <Divider />
            {!props.loading ?//|| props.details ?
                <>{(props?.details && props.details.filter(d => { return !filter || filter.length === 0 ? true : d?.properties?.details?.name?.value?.toLowerCase().includes(filter.toLowerCase()) }).length > 0) ?
                    <div className={classes.features}>
                        <List dense={true}>
                            {props.details.filter(d => { return !filter || filter.length === 0 ? true : d.properties.details.name.value.toLowerCase().includes(filter.toLowerCase()) }).map(feature => {
                                return <FeaturesListItem
                                    key={feature.properties.id + '_item'}
                                    details={feature}
                                    selectedFeature={props.selectedFeature}
                                    onSelect={onSelect}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                    onZoom={onZoom}
                                    onSave={onSave}
                                    onCancel={onCancel} />
                            })}
                        </List>
                    </div>
                    : <div className={classes.noItems}><Typography variant={'caption'}> No features</Typography></div>
                }</> : <div className={classes.center}>
                    <CircularProgress />
                </div>}
            {/* </div> */}
        </Paper>
    );
}
