import '../modules/Proj4Module'
import * as turf from '@turf/turf'
import { feature } from '@turf/turf'

const Utils = {}

Utils.deepMerge = (...objects) => {
    const isObject = (obj) => obj && typeof obj === 'object'

    function deepMergeInner(target, source) {
        Object.keys(source).forEach((key) => {
            const targetValue = target[key]
            const sourceValue = source[key]

            if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
                target[key] = targetValue.concat(sourceValue)
                // let t = targetValue.map((item, i) => Object.assign({}, item, sourceValue[i]));
                // target[key] = targetValue.map((item, i) => Object.assign({}, item, sourceValue[i]));
                // target[key] = [targetValue, sourceValue].reduce((a, b) => a.map((c, i) => Object.assign({}, c, b[i])));
                // let t = [targetValue, sourceValue].map((a, b) => deepMergeInner(a,b));
                // target[key] = t[0].concat(t[1]);
            } else if (isObject(targetValue) && isObject(sourceValue)) {
                target[key] = deepMergeInner({ ...targetValue }, sourceValue)
            } else {
                target[key] = sourceValue
            }
        })

        return target
    }

    if (objects.length < 2) {
        throw new Error('deepMerge: this function expects at least 2 objects to be provided')
    }

    if (objects.some((object) => !isObject(object))) {
        throw new Error('deepMerge: all values should be of type "object"')
    }

    const target = objects.shift()
    let source

    while ((source = objects.shift())) {
        deepMergeInner(target, source)
    }

    return target
}

Utils.reProject = (lat, lng) => {
    if (!window.proj4) throw 'proj4 not initialised'

    return window.proj4 ? window.proj4('EPSG:4326', 'EPSG:900913', [lng, lat]) : [lng, lat]
}

Utils.getPixelLocation = (latlng, googleMap) => {
    if (!window.google) throw 'Google Maps not initialised'
    if (!googleMap) throw 'Google Maps object is required'

    const scale = Math.pow(2, googleMap.getZoom())
    // The NorthWest corner of the current viewport corresponds
    // to the upper left corner of the map.
    // The script translates the coordinates of the map's center point
    // to screen coordinates. Then it subtracts the coordinates of the
    // coordinates of the map's upper left corner to translate the
    // currentLatLng location into pixel values in the <div> element that hosts the map.
    const nw = new window.google.maps.LatLng(
        googleMap.getBounds().getNorthEast().lat(),
        googleMap.getBounds().getSouthWest().lng()
    )
    // Convert the nw location from geo-coordinates to screen coordinates
    const worldCoordinateNW = googleMap.getProjection().fromLatLngToPoint(nw)
    // Convert the location that was clicked to screen coordinates also
    const worldCoordinate = googleMap.getProjection().fromLatLngToPoint(latlng)
    const pixelLocation = new window.google.maps.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
    )
    return pixelLocation
}

Utils.angleFromCoords = (lat1, lat2, lng1, lng2) => {
    const dlng = lng2 - lng1
    const y = Math.sin(dlng) * Math.cos(lat2)
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dlng)
    const brng = Math.atan2(y, x)
    return Math.round(((brng * 180) / Math.PI + 360) % 360) // in degrees
}

Utils.getComponent = (place, component1, component2, component3, component4, component5, component6) => {
    if (!place) return ''
    for (let i = 0; i < place.address_components.length; i++) {
        const base = place.address_components[i].types.indexOf(component1) > -1
        const one = place.address_components[i].types.indexOf(component2) > -1
        const two = place.address_components[i].types.indexOf(component3) > -1
        const three = place.address_components[i].types.indexOf(component4) > -1
        const four = place.address_components[i].types.indexOf(component5) > -1
        const five = place.address_components[i].types.indexOf(component6) > -1
        if (base || one || two || three || four || five) {
            const result =
                place.address_components[i].long_name.length > 31
                    ? place.address_components[i].long_name.substring(0, 31)
                    : place.address_components[i].long_name
            return result.replace(/&/g, '').replace(/=/g, '')
        }
    }
    return 'NA'
}

Utils.getAddressStringFromPlace = (place) => {
    if (!place) return

    return `
    ${place.address_components[0] && place.address_components[0].long_name}
    ${place.address_components[1] && place.address_components[1].long_name}
    ${place.address_components[2] && place.address_components[2].long_name}
    ${place.address_components[3] && place.address_components[3].long_name}
    `
}

Utils.getAddressURLStringFromPlace = (place) => {
    if (!place) return

    return `&province=${Utils.getComponent(place, 'administrative_area_level_1')}&town=${Utils.getComponent(
        place,
        'locality'
    )}&suburb=${Utils.getComponent(
        place,
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
        'sublocality_level_5'
    )}&street=${Utils.getComponent(place, 'route')}&streetnumber=${Utils.getComponent(
        place,
        'street_number'
    )}&postalcode=${Utils.getComponent(place, 'postal_code')}`
}

Utils.getLatLngStringFromPlace = (place) => {
    if (!place) return
    return `&latitude=${place.geometry.location.lat()}&longitude=${place.geometry.location.lng()}`
}

Utils.parseFno = (activeFno) => {
    const compareObj = {
        // Home Fibre
        openserve: 'Openserve ',
        frogfoot: 'Frogfoot',
        vumatelcore: 'Vumatel Core',
        vumatelreach: 'Vumatel Reach',
        // Business Fibre
        clearaccess: 'ClearAccess',
        dfa: 'DFA Buildings',
        dfamagellan_zone1: 'DFA Magellan Zone 1',
        dfamagellan_zone2: 'DFA Magellan Zone 2',
        dfaductbank: 'DFA Ductbanks',
        openservefttb: 'Openserve',
        linkafrica: 'LinkAfrica',
        dnatel: 'DNATel',
        openfibre: 'OpenFibre',
        evotel: 'Evotel',
        ttconnect: 'TTConnect',
        iswitchtowers: 'iSwitch',
        metrofibrebuildings: 'MFN Buildings',
        metrofibre: 'MFN Fibre',
        mtn: 'MTN Fixed LTE',
        linkafricabuildings: 'LinkAfrica Buildings',
        saicom: 'Saicom',
        // Towers
        skywire: 'Skywire Microwave',
        vodacom: 'Vodacom PTP Microwave',
        voconnect: 'VO Connect',
        gps: 'GPS',
        triqa: 'Triqa',
        datadimension: 'Data Dimension',

    }

    const convertedfno = compareObj[activeFno]

    if (convertedfno === undefined) {
        return activeFno
    }
    return convertedfno
}

// This returns only the active layer FNO's and statuses
Utils.getActiveFnosAndStatusArrays = (coverage, layers) => {
    const activeLayer = layers.find((layer) => layer.active === true)

    const fnosAndStatusObject = coverage.services.reduce((holdingObject, { providers, type }) => {
        let fnosArray = holdingObject.fnos || []
        let statusArray = holdingObject.status || []
        let fnosComingSoonArray = holdingObject.fnosComingSoonArray || []
        let statusComingSoonArray = holdingObject.statusComingSoonArray || []

        if (type === activeLayer.layerService) {
            providers.forEach(({ provider, status }) => {
                if (status === true
                    || status === 'live') {
                    fnosArray.push(provider)
                    statusArray.push(status)
                } else if (
                    status === 'comingsoon'
                    || status === 'inprogress'
                    || status === 'planned') {
                    fnosComingSoonArray.push(provider)
                    statusComingSoonArray.push(status)
                }
            })
        }


        return {
            fnos: fnosArray,
            status: statusArray,
            comingsoonFnos: fnosComingSoonArray,
            comingsoonStatus: statusComingSoonArray
        }
    }, {})
    return fnosAndStatusObject
}

Utils.getAllFnosAndStatusArrays = (coverage) => {


    const fnosAndStatusObject = coverage.services.reduce((holdingObject, { providers, type }) => {
        let fnosArray = holdingObject.fnos || []
        let statusArray = holdingObject.status || []
        let fnosComingSoonArray = holdingObject.fnosComingSoonArray || []
        let statusComingSoonArray = holdingObject.statusComingSoonArray || []


        providers.forEach(({ provider, status }) => {
            if (status === true
                || status === 'live') {
                fnosArray.push(provider)
                statusArray.push(status)
            } else if (
                status === 'comingsoon'
                || status === 'inprogress'
                || status === 'planned') {
                fnosComingSoonArray.push(provider)
                statusComingSoonArray.push(status)
            }
        })

        return {
            fnos: fnosArray,
            status: statusArray,
            comingsoonFnos: fnosComingSoonArray,
            comingsoonStatus: statusComingSoonArray
        }
    }, {})
    return fnosAndStatusObject
}

Utils.getFnosAndStatusArrays = (coverage, layers) => {
    const activeLayers = layers.filter((layer) => layer.active === true)

    const fnosAndStatusObject = coverage.services.reduce((holdingObject, { providers, type }) => {
        let fnosArray = holdingObject.fnos || []
        let statusArray = holdingObject.status || []
        let fnosComingSoonArray = holdingObject.fnosComingSoonArray || []
        let statusComingSoonArray = holdingObject.statusComingSoonArray || []

        for (const activeLayer of activeLayers) {

            if (type === activeLayer.layerService) {
                providers.forEach(({ provider, status }) => {
                    if (status === true
                        || status === 'live') {
                        fnosArray.push(provider)
                        statusArray.push(status)
                    } else if (
                        status === 'comingsoon'
                        || status === 'inprogress'
                        || status === 'planned') {
                        fnosComingSoonArray.push(provider)
                        statusComingSoonArray.push(status)
                    }
                })
            }
        }


        return {
            fnos: fnosArray,
            status: statusArray,
            comingsoonFnos: fnosComingSoonArray,
            comingsoonStatus: statusComingSoonArray
        }
    }, {})
    return fnosAndStatusObject
}

Utils.uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

Utils.toJson = (json) => {
    try {
        return JSON.parse(json)
    } catch (e) {
        return false
    }
}

Utils.toFeatureCollection = (features) => {
    if (!features) return

    return {
        "type": "FeatureCollection",
        "features": [...features]
    }
}

Utils.toFeatureArray = (featureCollection) => {
    if (!featureCollection) return

    return featureCollection.features
}

Utils.getFeaturePath = (feature) => {
    if (!feature) return

    let paths = feature.getPath().getArray()
    let newPaths = []
    paths.forEach(p => {
        newPaths.push(
            new google.maps.LatLng(p.lat(), p.lng())
        )
    })
    return newPaths
}

Utils.validFeature = (feature) => {
    if (!feature) return false

    let intersections = turf.kinks(feature)
    return intersections && intersections.features && intersections.features.length > 0 ? false : true
}

Utils.validateGeojson = (geojson) => {
    if (!geojson || !geojson.geometry || !geojson.geometry.type || geojson.geometry.type.toLowerCase() !== 'polygon' || !geojson.geometry.coordinates || geojson.geometry.coordinates.length === 0)
        return false
    return true
}


Utils.ParseFeatureCollection = (featureCollection, toLowerCase) => {
    let features = []

    featureCollection.features.forEach(feature => {
        features.push(Utils.ParseFeature(feature, toLowerCase))
    })

    return features
}


Utils.ParseFeature = (feature, toLowerCase = false) => {
    if (feature.properties) {
        if(toLowerCase) feature.properties = JSON.parse(JSON.stringify(feature.properties).toLowerCase())

        feature.properties.id = feature.properties.client_boundary_id || feature.properties.id ? feature.properties.id : Utils.uuid()
        feature.properties.type = feature.geometry.type.toLowerCase()
        feature.properties.details = feature.ui_config
        if (!feature.properties.details) {
            feature.properties.details = {}
        }
        if (!feature.properties.details.name && feature.properties.name) feature.properties.details.name = { value: feature.properties.name }
        if (!feature.properties.details.description && feature.properties.description) feature.properties.details.description = { value: feature.attributes.description }
        if (!feature.properties.details.type && feature.properties.type) feature.properties.details.type = { value: { value: feature.properties.type } }
        feature.properties.details.active = { value: !!feature.properties.active }
    }

    return feature
}

export default Utils
