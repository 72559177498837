import React, { createContext, useEffect, useReducer } from 'react'

import routes from '../../routes/routes'

import { reducer } from './reducer'

export const UserContext = createContext()

const UserProvider = ({ children }) => {
    const [user, dispatchUser] = useReducer(reducer, {
        isAuthenticated: false,
        isAuthorized: false,
        isVerifying: false,
        details: null,
        error: null
    })

    return <UserContext.Provider value={{ user, dispatchUser }}>{children}</UserContext.Provider>
}

export default UserProvider
