import React, { createContext, useReducer } from 'react'

import AssasinscreedMapStyle from '../../mapstyles/assasinscreed.mapstyle'
import DarkMapStyle from '../../mapstyles/dark.mapstyle'
import DefaultMapStyle from '../../mapstyles/default.mapstyle'
import GreyMapStyle from '../../mapstyles/grey.mapstyle'

import { reducer } from './reducer'

export const BrandContext = createContext()

const BrandProvider = ({ children }) => {
  const [brand, dispatchBrand] = useReducer(reducer, {
    clientName: '28east',
    logo: './logo.png',
    title: '',
    coverageAPI: {
      apikey: process.env.REACT_APP_COVERAGE_API_KEY,
    },
    towersAPI: {
      apiKey: '',
    },
    firebaseConfig: {
      apiKey: "AIzaSyA7V7NwYgRCN_ZvFpiqfRJfX3TRXZ1Gq28",
      authDomain: "coverage-hosting.firebaseapp.com",
      databaseURL: "https://coverage-hosting.firebaseio.com",
      projectId: "coverage-hosting",
      storageBucket: "coverage-hosting.appspot.com",
      messagingSenderId: "174293551375",
      appId: "1:174293551375:web:5ff70a1efd7541ccfa967a",
      measurementId: "G-K03CCEEKC4"
    },
    googlemaps: {
      apikey: process.env.REACT_APP_GOOGLE_API_KEY,
      // mapstyle: GreyMapStyle.style,
      center: { lat: -28, lng: 25 }, // { lat: -28, lng: 25 }
      markerZoomLevel: 13, // can be omitted default is 12
    },
    authentication: true,
    color: {
      primary: {
        light: '#575d60',
        main: '#3F484E',
        dark: '#272e33',
        accent: '#aa060f',
        contrastText: '#fff',
      },
      secondary: {
        light: '#f93943',
        main: '#E11924',
        dark: '#aa060f',
        accent: '#272e33',
        contrastText: '#fff',
      },
      error: {
        light: '#ff0000',
        main: '#ed0202',
        dark: '#aa060f',
        accent: '#09c400',
        contrastText: '#fff',
      },
    },
    navbar: {
      show: true,
      position: 'fixed', // fixed, float
    },
    sidebar: {
      show: false,
    },
    loadingSpinner: {
      show: true,
    },
    fullHeight: true,
    css: {},
  })

  return <BrandContext.Provider value={{ brand, dispatchBrand }}>{children}</BrandContext.Provider>
}

export default BrandProvider
