import React, { useContext, useEffect, useState } from 'react'

import { Box, Button, Collapse, Divider, ListSubheader, Radio, Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import CommentIcon from '@material-ui/icons/Comment'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { set } from 'lodash'

import { BrandContext } from '../context/BrandContext/context'
import { SiteContext } from '../context/SiteContext/context'

import Legend from './Legend'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  statusLegend: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    minWidth: '250px'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '2pt',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    textAlign: 'center',
  },
  titleButton: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  titleText: {
    display: 'flex',
    alignItems: 'left',
    textAlign: 'left',
    width: '100%',
  },
  subheader: {
    lineHeight: 'inherit !important',
    paddingTop: '5pt',
    paddingBottom: '5pt',
  }
  // liveStatus: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     padding: '2pt',
  //     backgroundColor: props.liveStatus && props.liveStatus.color ? props.liveStatus.color : theme.palette.live && theme.palette.live.main ? theme.palette.live.main : theme.palette.primary.main,
  //     color: props.liveStatus && props.liveStatus.contrastText ? props.liveStatus.contrastText : theme.palette.live && theme.palette.live.contrastText ? theme.palette.live.contrastText : theme.palette.primary.contrastText,
  //     width: '50%'
  // },
  // comingSoon: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     padding: '2pt',
  //     backgroundColor: props.comingSoonStatus && props.comingSoonStatus.color ? props.comingSoonStatus.color : theme.palette.comingsoon && theme.palette.comingsoon.main ? theme.palette.comingsoon.main : theme.palette.secondary.main,
  //     color: props.comingSoonStatus && props.comingSoonStatus.contrastText ? props.comingSoonStatus.contrastText : theme.palette.comingsoon && theme.palette.comingsoon.contrastText ? theme.palette.comingsoon.contrastText : theme.palette.secondary.contrastText,
  //     width: '50%',
  //     textAlign: 'center'
  // },
}))

export default function OverlayLayerSelector(props) {
  const { settings } = useContext(SiteContext)
  const { brand } = useContext(BrandContext)
  const classes = useStyles()
  const [initialised, setInitialised] = React.useState(false)
  const [checked, setChecked] = React.useState([])
  const [openItems, setOpenItems] = React.useState([])
  const openItemsRef = React.useRef([])
  const [layerCollapseOpen, setLayerCollapseOpen] = React.useState(!settings.isMobile)
  const [layerLiveColor, setLayerLiveColor] = useState()
  const [layerComingSoonColor, setLayerComingSoonColor] = useState()
  const handleLayerCollapseOpen = () => {
    const open = !layerCollapseOpen
    setLayerCollapseOpen(open)
  }

  const handleItemToggle = (index) => {
    let tmp = openItemsRef.current
    const indexes = tmp.filter((i) => i === index)
    if (indexes && indexes.length > 0) tmp = tmp.filter((i) => i !== index)
    else tmp.push(index)
    openItemsRef.current = tmp
  }

  const handleLayerDisplayToggle = (value) => () => {
    console.log('handleLayerDisplayToggle | value', value)
    const currentIndex = checked.indexOf(value.layerName)
    let newChecked = [...checked]
    if (!props.singleSelect) {
      if (currentIndex === -1) {
        newChecked.push(value.layerName)
      } else {
        newChecked.splice(currentIndex, 1)
      }
    } else {
      newChecked = [value.layerName]
    }
    setChecked(newChecked)
    if(value.liveStatus) setLayerLiveColor(value.liveStatus)
    if(value.comingSoonStatus) setLayerComingSoonColor(value.comingSoonStatus)
    if (props.handleLayerItemClick) props.handleLayerItemClick(value)
  }

  const handleLayerLegendDisplayToggle = (value) => () => {
    const currentIndex = openItems.indexOf(value.layerName)
    const newChecked = [...openItems]
    if (currentIndex === -1) {
      newChecked.push(value.layerName)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setOpenItems(newChecked)
  }

  const getSubheadings = () => {
    const subheadingsObj = {}
    props.layers.forEach((layer) => {
      const serviceName = layer.layerServiceName ? layer.layerServiceName : layer.layerService
      if (!subheadingsObj[serviceName]) subheadingsObj[serviceName] = []
      subheadingsObj[serviceName].push(layer)
    })
    const subheadings = []

    for (const key in subheadingsObj) {
      subheadings.push({
        heading: key,
        values: subheadingsObj[key],
      })
    }

    return subheadings
  }

  return (
    <div>
      {props.displayToggle && (
        <>
          <div className={classes.titleContainer}>
            <Button className={classes.titleButton} onClick={handleLayerCollapseOpen}>
              <span>{props.toggleTitle ? props.toggleTitle : 'Toggle Layers'} </span>
              {layerCollapseOpen ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </div>
          <Divider />
        </>
      )}
      {props.layers && props.layers.length > 0 && (
        <Collapse in={layerCollapseOpen} timeout='auto' unmountOnExit key='overlay-layer-item'>
          {!props.useSubheadings ? (
            <List className={classes.root} dense>
              {props.layers.map((value, i) => {
                if (value.display && !value.hideFromSelection)
                  return (
                    <div key={`overlay-layer-item-${i}-container`} id="layerControl">
                      <ListItem
                        key={`overlay-layer-item-${i}-key-item`}
                        role={undefined}
                        onClick={handleLayerDisplayToggle(value)}
                        dense
                        button
                      >
                        <ListItemIcon>
                          {!props.singleSelect ? (
                            <Checkbox
                              onClick={handleLayerDisplayToggle(value)}
                              edge='start'
                              checked={
                                value.active !== undefined && value.active !== 'undefined'
                                  ? value.active
                                  : checked.indexOf(value.layerName) !== -1
                              }
                              tabIndex={-1}
                              disableRipple
                              color={value.color ? value.color : 'default'}
                              inputProps={{
                                'aria-labelledby': `overlay-layer-item-${i}`,
                              }}
                            />
                          ) : (
                            <Radio
                              onClick={handleLayerDisplayToggle(value)}
                              edge='start'
                              checked={
                                value.active !== undefined && value.active !== 'undefined'
                                  ? value.active
                                  : checked.indexOf(value.layerName) !== -1
                              }
                              tabIndex={-1}
                              disableRipple
                              color={value.color ? value.color : 'default'}
                              inputProps={{
                                'aria-labelledby': `overlay-layer-item-${i}`,
                              }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText id={`overlay-layer-item-${i}`} primary={`${value.title}`} />
                        {props.layerLegend && (
                          <Button
                            onClick={handleLayerLegendDisplayToggle(value)}
                            key={`overlay-layer-item-${i}-key-button`}
                          >
                            {openItems.indexOf(value.layerName) !== -1 ? <ExpandLess /> : <ExpandMore />}
                          </Button>
                        )}
                      </ListItem>

                      {props.layerLegend && !value.noLegend && (
                        <Collapse
                          in={openItems.indexOf(value.layerName) !== -1}
                          key={`overlay-layer-item-${i}-key-collapse-legend`}
                        >
                          <Legend
                            key={`overlay-layer-item-${i}-key-legend`}
                            liveStatus={value.liveStatus}
                            comingSoonStatus={value.comingSoonStatus}
                            hideLive={value.hideLive}
                            hideComingSoon={value.hideComingSoon}
                          />
                        </Collapse>
                      )}
                    </div>
                  )
                return false
              })}
            </List>
          ) : (
            getSubheadings().map((item) => (
              <List
                key={`overlay-layer-item-${item.heading}-subheader-list`}
                className={classes.root}
                dense
                subheader={
                  <div key={`overlay-layer-item-${item.heading}-subheader`}>
                    <ListSubheader component='div' id='nested-list-subheader' className={classes.subheader}>
                      {item.heading}
                    </ListSubheader>
                    <Divider />
                  </div>
                }
              >
                {item.values.map((value, i) => {
                  if (value.display)
                    return (
                      <div key={`overlay-layer-item-${i}-container`}>
                        {/* <ListItem key={`overlay-layer-item-${i}-key`} role={undefined} dense button onClick={handleLayerDisplayToggle(value)}> */}
                        <ListItem
                          key={`overlay-layer-item-${i}-key-item`}
                          role={undefined}
                          onClick={handleLayerDisplayToggle(value)}
                          dense
                          button
                        >
                          <ListItemIcon>
                            {!props.singleSelect ? (
                              <Checkbox
                                onClick={handleLayerDisplayToggle(value)}
                                edge='start'
                                checked={
                                  value.active !== undefined && value.active !== 'undefined'
                                    ? value.active
                                    : checked.indexOf(value.layerName) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                color={value.color ? value.color : 'default'}
                                inputProps={{
                                  'aria-labelledby': `overlay-layer-item-${i}`,
                                }}
                              />
                            ) : (
                              <Radio
                                onClick={handleLayerDisplayToggle(value)}
                                edge='start'
                                checked={
                                  value.active !== undefined && value.active !== 'undefined'
                                    ? value.active
                                    : checked.indexOf(value.layerName) !== -1
                                }
                                tabIndex={-1}
                                disableRipple
                                color={value.color ? value.color : 'default'}
                                inputProps={{
                                  'aria-labelledby': `overlay-layer-item-${i}`,
                                }}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText id={`overlay-layer-item-${i}`} primary={`${value.title}`} />
                          {props.layerLegend && !value.noLegend && (
                            <Button
                              onClick={handleLayerLegendDisplayToggle(value)}
                              key={`overlay-layer-item-${i}-key-button`}
                            >
                              {openItems.indexOf(value.layerName) !== -1 ? <ExpandLess /> : <ExpandMore />}
                            </Button>
                          )}
                        </ListItem>

                        {props.layerLegend && !value.noLegend && (
                          <Collapse
                            in={openItems.indexOf(value.layerName) !== -1}
                            key={`overlay-layer-item-${i}-key-collapse-legend`}
                          >
                            <Legend
                              key={`overlay-layer-item-${i}-key-legend`}
                              liveStatus={value.liveStatus}
                              comingSoonStatus={value.comingSoonStatus}
                              hideLive={value.hideLive}
                              hideComingSoon={value.hideComingSoon}
                            />
                          </Collapse>
                        )}
                      </div>
                    )
                  return false
                })}
              </List>
            ))
          )}
        </Collapse>
      )}
      {!props.layerLegend && (
        <div className={classes.statusLegend}>
          <Legend
            liveStatus={layerLiveColor}
            comingSoonStatus={layerComingSoonColor}
          />
        </div>
      )}
    </div>
  )
}
