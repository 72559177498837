import React, { useContext, useEffect, useState, useRef } from 'react'

import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, Input, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleOutline, Fullscreen, HighlightOff } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import StopIcon from '@material-ui/icons/Stop';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';

import Utils from '../../utils/Utils'
import { GithubPicker } from 'react-color';
import Palette from '@material-ui/icons/Palette';
import PaletteIcon from '@material-ui/icons/Palette'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // position: 'absolute',
        zIndex: '1'
    },
    contentRoot: {
        width: '100%',
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(1),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        // margin: theme.spacing(1),
    },
    fullWidth: {
        width: '100%'
    },
    callToAction: {
        // textAlign: 'right'
    },
    actionButtons: {
        textAlign: 'right'
    },
    actionIcons: {
        textAlign: 'left'
    }
}))

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function DynamicForm(props) {
    const classes = useStyles()

    const ref = useRef()
    const [paletteOpen, setPaletteOpen] = useState(false)
    const [details, setDetails] = useState(Object.assign({}, props.details))
    const [required, setRequired] = useState({})

    useEffect(() => {
        prepareDetails()
    }, [])

    useEffect(() => {
        prepareDetails()

    }, [props.details])

    useEffect(() => {
        prepareDetails()

    }, [props.schema])

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                closePalette()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])

    useEffect(() => {
        if (props.onChange) {
            props.onChange(parseDetails(), validateFields())
        }
    }, [details])

    const prepareDetails = () => {
        let newDetails = Object.assign({}, props.schema)
        if (props.details) {
            Object.keys(props.details).forEach(k => {
                if (newDetails[k]) {
                    if (props.details[k].value.title || !newDetails[k].lookup || (Array.isArray(props.details[k].value) && props.details[k].value.length > 0))
                        newDetails[k].value = props.details[k].value
                    else if (newDetails[k].lookup) {
                        let p = newDetails[k].lookup.filter(f => f.value === props.details[k].value)
                        if (p.length > 0) newDetails[k].value = p[0]
                    }
                }
            })
            setDetails(newDetails)
        }
    }

    const parseDetails = () => {
        let newDetails = {}
        Object.keys(details).forEach(k => {
            if (details[k] && details[k].value && details[k].value.value) {
                if (details[k].value.default && (!details[k].value.value || details[k].value.value === ''))
                    details[k].value.value = details[k].default
                newDetails[k] = { value: details[k].value.value }
            }
            else {
                if (details[k].default && (!details[k].value || details[k].value === ''))
                    details[k].value = details[k].default
                newDetails[k] = { value: details[k].value }
            }
        })
        return newDetails
    }

    const copyObj = (obj) => {
        if (!obj) return
        return JSON.parse(JSON.stringify(obj))
    }

    const parseSchemaToObject = (schema) => {
        let tmpDetails = {}
        for (let key in schema) {
            let obj = schema[key]
            if (obj.lookup && obj.multiple) tmpDetails[key] = obj.value ? obj.value : []
            else if (obj.type === 'String') tmpDetails[key] = obj.value ? obj.value : ''
            else if (obj.type === 'Number') tmpDetails[key] = obj.value ? obj.value : 0
            else if (obj.type === 'Object') {
                if (obj.value && obj.value !== {})
                    tmpDetails[key] = parseSchemaToObject(obj.value)
                else tmpDetails[key] = {}
            }
        }
        return tmpDetails
    }

    const handleClose = () => {
        if (props.handleClose) props.handleClose()
    }

    const validateFields = () => {
        let invalid = { ...required }
        Object.keys(details).forEach(k => {
            if (details[k].required && !details[k].value) {
                invalid[k] = true
            } else if (details[k].required && details[k].lookup) {
                let tmp = details[k].lookup.filter(l => l.value === details[k].value.value)
                if (tmp.length > 0) delete invalid[k]
                else invalid[k] = true
            } else if (details[k].type === 'JSON') {
                try {
                    if (details[k].value && details[k].value.length > 0) JSON.parse(details[k].value)
                    delete invalid[k]
                } catch (e) {
                    invalid[k] = true
                }
            }
            else if (invalid[k]) {
                delete invalid[k]
            }
        })
        setRequired(invalid)
        return Object.keys(invalid).length === 0
    }

    const handleSave = () => {

        if (!props.handleSave) return handleClose()

        if (validateFields()) {
            props.handleSave(parseDetails())
            setDetails({})
        }
    }

    const handleLookupSelect = (e, key) => {
        let tmpDetails = { ...details }
        tmpDetails[key].value = e.target.value
        setDetails(tmpDetails)
    }
    const handleAutocompleteSelect = (e, newValue, key) => {
        let tmpDetails = { ...details }
        tmpDetails[key].value = newValue
        setDetails(tmpDetails)
    }

    const handleValueChange = (value, key) => {
        let tmpDetails = { ...details }
        tmpDetails[key].value = value
        setDetails(tmpDetails)
    }

    const onPalette = (e, value) => {
        if (!paletteOpen) setPaletteOpen(true)
        else setPaletteOpen(false)
    }

    const closePalette = () => {
        setPaletteOpen(false)
    }

    const handleColorPicker = (value) => {
        let tmpDetails = { ...details }
        tmpDetails['color'].value = value.hex
        setDetails(tmpDetails)
        closePalette()
    }

    return (
        <div
            className={classes.root}>
            <Typography variant="h6" id="form-dialog-title">{props.title ?? 'Feature'}</Typography>
            <div className={classes.content}>
                {(details) ?
                    Object.keys(details).map(key => {
                        let prop = details[key]
                        if (prop.lookup && !prop.multiple)
                            return <Autocomplete
                                disabled={props.loading}
                                value={details[key].value}
                                className={classes.formControl}
                                id={key + '_lookup_select'}
                                key={key + '_lookup'}

                                options={prop.lookup}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, newValue) => {
                                    handleAutocompleteSelect(e, newValue, key)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        disabled={props.loading}
                                        error={required[key]}
                                        label={prop.title + (prop.required ? '*' : '')}
                                        placeholder={prop.title}
                                        helperText={prop.hint ? prop.hint : ''} />
                                )}
                            />
                        else if (prop.lookup && prop.multiple)
                            return <Autocomplete
                                disabled={props.loading}
                                multiple
                                limitTags={2}
                                value={details[key].value}
                                className={classes.formControl}
                                id={key + '_lookup_select'}
                                key={key + '_lookup'}
                                options={prop.lookup}
                                // disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                onChange={(e, newValue) => {
                                    handleAutocompleteSelect(e, newValue, key)
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={`${option.title}_tag`}
                                            variant="outlined"
                                            label={option.title}
                                            size="small"
                                            {...getTagProps(index)}
                                        />
                                    ))
                                }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            disabled={props.loading}
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            size="small"
                                        />
                                        {option.title}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        disabled={props.loading}
                                        error={required[key]}
                                        label={prop.title + (prop.required ? '*' : '')}
                                        placeholder={prop.title}
                                        helperText={prop.hint ? prop.hint : ''} />
                                )}
                            />
                        else if (prop.type === 'Boolean')
                            return (
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={props.loading}
                                                error={required[key]}
                                                checked={details[key].value || details[key].value === 'true'}
                                                onChange={(e) => {
                                                    handleValueChange(e.target.checked, key)
                                                }} />
                                        }
                                        label={prop.title + (prop.required ? '*' : '')}
                                    />
                                    {prop.hint && <FormHelperText>{prop.hint}</FormHelperText>}
                                </FormControl>
                            )
                        else if (prop.type === 'String' && !prop.multiline)
                            return <TextField
                                disabled={props.loading}
                                error={required[key]}
                                value={details[key].value}
                                onChange={(e) => {
                                    handleValueChange(e.target.value, key)
                                }}
                                key={key + '_txt'}
                                margin="dense"
                                id={key + "_txt"}
                                label={prop.title + (prop.required ? '*' : '')}
                                helperText={prop.hint ? prop.hint : ''}
                                type="text"
                                fullWidth
                            />
                        else if (prop.type === 'JSON' && prop.multiline)
                            return <TextField
                                disabled={props.loading}
                                multiline
                                error={required[key]}
                                value={details[key].value}
                                onChange={(e) => {
                                    handleValueChange(e.target.value, key)
                                }}
                                style={{ textAlign: 'left' }}
                                key={key + '_txt'}
                                margin="dense"
                                id={key + "_txt"}
                                label={prop.title + (prop.required ? '*' : '')}
                                helperText={prop.hint ? prop.hint : ''}
                                type="text"
                                fullWidth
                            />
                        else if (prop.type === 'Number')
                            return <TextField
                                disabled={props.loading}
                                error={required[key]}
                                key={key + '_nbr'}
                                value={details[key].value}
                                onChange={(e) => {
                                    handleValueChange(e.target.value, key)
                                }}
                                margin="dense"
                                id={key + "_txt"}
                                label={prop.title + (prop.required ? '*' : '')}
                                type="number"
                                helperText={prop.hint ? prop.hint : ''}
                                fullWidth
                            />
                        else if (prop.type === 'Object')
                            return <></>
                        else
                            return <></>
                    })
                    : <Typography>No information to display</Typography>
                }
            </div>

            <div className={classes.callToAction}>
                {!props.onChange && <div className={classes.actionButtons}>
                    <Button onClick={handleClose} disabled={props.loading} color="default">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={props.loading} color="primary">
                        Save
                    </Button>
                </div>}
            </div>
        </div>
    )
}
