import React, { createContext, useEffect, useReducer } from 'react'

import routes from '../../routes/routes'

import { reducer } from './reducer'

export const SiteContext = createContext()

const detectMobileView = () => {
    const ratio = window.devicePixelRatio || 1
    const w = window.innerWidth / ratio
    return w < 400
}

const SiteProvider = ({ children }) => {
    const [settings, dispatchSettings] = useReducer(reducer, {
        routes,
        activeView: routes && routes.length > 0 ? routes[0].component : undefined,
        isMobile: detectMobileView(),
        pageWidth: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        pageHeight: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
        currentAddress: '',
    })

    return <SiteContext.Provider value={{ settings, dispatchSettings }}>{children}</SiteContext.Provider>
}

export default SiteProvider
