import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Paper, TextField, Typography, Grid, Button, Divider, Snackbar, Container } from '@material-ui/core'
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import fetchJsonp from 'fetch-jsonp'

import GoogleAutocompleteWidget from '../components/GoogleAutoCompleteWidget/GoogleAutocompleteWidget'
import GoogleMap from '../components/GoogleMap'
import GoogleSearchBox from '../components/GoogleSearchBox'
import Loader from '../components/Loader/Loader'
import TowerContent from '../components/Marker/MarkerContent/TowerContent'
import VerboseMarkerContent from '../components/Marker/MarkerContent/VerboseMarkerContent/VerboseMarkerContent'
import OverlayLayerSelector from '../components/OverlayLayerSelector'
import { BrandContext } from '../context/BrandContext/context'
import { SiteContext } from '../context/SiteContext/context'
import DrawLine from '../services/DrawLine'
import Geolocation from '../services/Geolocation'
import WMSOverlay from '../services/WMSOverlay'
import Utils from '../utils/Utils'
import DynamicFormContent from '../components/Dialogs/DynamicFormDialog'
import DynamicFormDialog from '../components/Dialogs/DynamicFormDialog'
import DynamicForm from '../components/Dialogs/DynamicForm'
import BasicBoundaryFormDialog from '../components/Dialogs/BasicBoundaryFormDialog'
import FeaturesList from '../components/Lists/FeaturesList'
import * as turf from '@turf/turf'
import { feature } from '@turf/turf'
import MarkerContainer from '../components/Marker/MarkerContainer'
import Alert from '../components/Alerts/Alert'
import { UserContext } from '../context/UserContext/context'
import DataController from '../controllers/DataController'
import { DataContext } from '../context/DataContext/DataContext'
import DynamicLabels from '../components/Dialogs/DynamicLabels'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab'
import { SET_USER } from '../context/UserContext/types'
import { SET_BOUNDARIES, SET_DATA } from '../context/DataContext/DataTypes'
import GroupFeaturesList from '../components/Lists/GroupFeaturesList'
import SpatialFileUpload from '../components/SpatialFileUpload'
import DataImportProcessFlow from '../components/DataImportProcessFlow'

// require('es6-promise').polyfill();
const ReactDOMServer = require('react-dom/server')

const GoogleDefaultInfoWindow = require('../utils/GoogleDefaultInfoWindow')

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  rootNav: {
    display: 'flex',
    height: '100%',
  },
  googleMaps: {
    position: 'absolute',
  },
  userControls: {
    position: 'absolute',
    zIndex: '1000',
    width: '100%',
    maxWidth: '400px'
  },
  userControlsLayers: {
    position: 'absolute',
    zIndex: '1000',
    width: '100%',
    maxWidth: '250px',
    marginTop: '48px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '48px',
    },
  },
  layerControls: {
    width: '100%',
    maxWidth: '250px',
    paddingTop: '1pt',
  },
  autocompleteContainer: {
    width: '100%',
    // maxWidth: '400px',
    display: 'flex',
  },
  heightContainer: {
    maxWidth: '100px',
    paddingTop: '4pt',
    paddingLeft: '4pt',
  },
  numberSpiner: {
    margin: 0,
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  controls: {
    position: 'absolute',
    left: '400px',
    zIndex: '1'
  },
  modal: {
    maxWidth: '600px'
  },
  features: {

  },
  clientSelect: {
    width: '100%',
    marginTop: '2pt',
  },
  migrateBtn: {
    position: 'absolute',
    right: '10pt',
    bottom: '18pt',
  },
  uploadBtn: {
    position: 'absolute',
    right: '10pt',
    bottom: '48pt',
  },
  important: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold'
  }
}))

export default function ClientBoundariesContainer(props) {
  const { brand } = useContext(BrandContext)
  const { settings } = useContext(SiteContext)
  const { user, dispatchUser } = useContext(UserContext)
  const { data, dispatchData } = useContext(DataContext)
  const classes = useStyles()

  const [selectedCategory, setSelectedCategory] = useState("")
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteCategoryConfirmation, setDeleteCategoryConfirmation] = useState(false)
  const [migrateOpen, setMigrateOpen] = useState(false)
  const [migrateOpenModal, setMigrateOpenModal] = useState(false)
  const [uploadStartOpen, setUploadStartOpen] = useState(false)

  const [searchingLocation, setSearchingLocation] = useState(true)
  const [currentDisplayAddress, setCurrentDisplayAddress] = useState()
  const [place, setPlace] = useState()
  const [selectedColor, setSelectedColor] = useState()
  const [colors, setColors] = useState(['#5b5b5b', '#1E90FF', '#FF1493', '#000', '#FF8C00', '#4B0082'])
  const [zoneState, setZoneState] = useState([{ title: 'Promotional', value: 'promotional' }, { title: 'Exclusion', value: 'exclusion' }])
  const [booleanValues, setBooleanValues] = useState([{ title: 'True', value: 'true' }, { title: 'False', value: 'false' }])
  // const [statuses, setStatuses] = useState([{ title: 'Live', value: 'live' }, { title: 'In Progress', value: 'inprogress' }])
  // const [services, setServices] = useState([{ title: 'Home Fibre', value: 'fibre' }, { title: 'Business Fibre', value: 'businessfibre' }])
  // const [providers, setProviders] = useState([{ title: 'Openserve', value: 'openserve' }, { title: 'Frogfoot', value: 'frogfoot' }, { title: 'Vumatel', value: 'vumatel' }, { title: 'Metrofibre', value: 'metrofibre' }, { title: 'Link Africa', value: 'linkafrica' }])
  const [features, setFeatures] = useState()
  const [error, setError] = useState(false)
  const [warning, setWarning] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const allFeatures = useRef([])
  const [invalidFeatures, setInvalidFeatures] = useState([])
  const [editingFeatures, setEditingFeatures] = useState([])
  const [migrationData, setMigrationData] = useState()

  const markerRef = useRef(); //useState nod updating as component is not rendering at this stage
  const googleGeocoderRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
  const googlePlaceRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
  const [clientBoundariesLoaded, setClientBoundariesLoaded] = useState(false)

  const setAllFeatures = (newFeatures) => {
    setFeatures([...newFeatures])
    allFeatures.current = newFeatures
  }

  const [defaultSchema, setDefaultSchema] = useState({
    name: { title: 'Name', type: 'String', value: '', required: true, grouping: 'attributes' },
    description: { title: 'Description', type: 'String', value: '', grouping: 'attributes' },
    category: { title: 'Category', type: 'String', value: '', grouping: 'properties', default: 'no category' },
    attributes: { title: 'Attributes', hint: "Add custom attributes in JSON format", type: 'JSON', multiline: true, value: '', grouping: 'attributes' },
    type: { title: 'Type', type: 'String', value: '', lookup: zoneState, multiple: false, required: true, grouping: 'properties' },
    services: { title: 'Services', hint: 'If added, only selected services will be effected, otherwise subscription will be used.', type: 'String', value: [], lookup: data.services, multiple: true, required: false, grouping: 'coverage-filter' },
    providers: { title: 'Providers', hint: 'If added, only the selected providers will be effected, otherwise subscription for the specified service will be used', type: 'String', value: [], lookup: data.providers, multiple: true, grouping: 'coverage-filter' },
    statuses: { title: 'Statuses', hint: 'If added, only selected services, providers and statuses will be effected, otherwise subscription for the specified providers will be used', type: 'String', value: [], lookup: data.statuses, multiple: true, grouping: 'coverage-filter' },
    active: { title: 'Active', hint: 'Activate this feature to be consumed, if false this feature will be ignored', type: 'Boolean', value: false, grouping: 'properties' },
    // promote: { title: 'Promote To Production', hint: 'Promote this boundary to productions', type: 'Boolean', value: false, grouping: 'ui_config' },
  })
  const migrationSchema = {
    source: { title: 'Source', type: 'String', value: '', lookup: data.environments?.filter(e => e.value.toLowerCase() === process.env.REACT_APP_ENV), hint: 'The source boundaries that you would like to migrate', required: true },
    destination: { title: 'Destination', type: 'String', value: '', lookup: data.environments, hint: 'The destination to migrate the source boundaries to', required: true },
    revert: { title: 'Revert Migration', hint: 'Undo the migration to the destination environment', type: 'Boolean', value: false, },
  }
  const mapRef = useRef() // use Refe because of useState only updates the state after render, map not updating as the map does not rerender
  const drawingManager = useRef()
  const selectedShape = useRef()
  const [selectedShapeState, setSelectedShapeState] = useState()
  const [defaultGeomStyling, setDefaultGeomStyling] = useState({
    strokeWeight: 2,
    strokeColor: colors[3],
    fillColor: colors[3],
    fillOpacity: 0.45,
    errorColor: '#ff0000',
    editColor: '#f79d04'
  })
  const popupRef = useRef()
  const clickedLocation = useRef()

  const theme = useTheme()

  const [googleMapOptions, setGoogleMapOptions] = useState({
    map: {
      center: brand.googlemaps.center ? brand.googlemaps.center : { lat: -26, lng: 25 },
      zoom: 6,
      minZoom: 3,
      maxZoom: 20,
      scaleControl: false,
      clickableIcons: false,
      mapTypeControl: true,
      zoomControl: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapId: 'abb59449ec84acc2',
      // mapTypeId: 'hybrid',
      // styles: brand.googlemaps.mapstyle,
    },
    autocomplete: {
      componentRestrictions: {
        country: 'ZA',
      },
      fields: ['address_components', 'formatted_address', 'geometry'],
    },
  })

  // const [isLoading, setIsLoading] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [modalAddOpen, setModalAddOpen] = useState(false)

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    if (data.loading) setLoading(data.loading)
  }, [])

  useEffect(() => {
    clearSelection()
    allFeatures.current.forEach(f => {
      deleteFeatureLocal(f)
    })

    let newSchema = { ...defaultSchema }
    newSchema.services.lookup = data.services
    newSchema.providers.lookup = data.providers
    newSchema.statuses.lookup = data.statuses
    setDefaultSchema(newSchema)
    addClientBoundariesToMap(data.boundaries)
    setLoading(data.loading)
  }, [data])

  useEffect(() => {
    console.log('###, features', features)
  }, [features])

  const addClientBoundariesToMap = (boundaries) => {
    if (!boundaries || boundaries.length === 0) return
    clearSelection()
    allFeatures.current.forEach(f => {
      deleteFeatureLocal(f)
    })
    try {
      boundaries.forEach(boundary => {
        let existingBoundary = allFeatures.current.filter(f => f.properties.id === boundary.properties.id)
        if (!existingBoundary || existingBoundary.length === 0) {
          addPolygonFeature(boundary)
        }
      })
      setClientBoundariesLoaded(true)
    } catch (err) {
      console.log('error', err)
    }
  }

  // const getClientSubscriptions = () => {
  //   if (!user.token) return
  //   onLoading(true)
  //   let subscriptions = DataController.getClientSubscriptions(user.token)
  //   onLoading(false)
  //   console.log('!!!!!!!!!!!!!!!!! subscriptions', subscriptions)
  // }

  const onAddressChange = (place) => {
    if (typeof place === 'string') place = place.replace(/ +/g, " ")
    googlePlaceRef.current = place
    setPlace(place)
    if (!place) {
      if (markerRef.current) markerRef.current.setMap(null)
      markerRef.current = null
    }
    updatePopup()
    if (place && place.geometry) {
      handleMarker(new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()))
      goToPosition(new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()))
    } else {
      let coords = validateCoords(place)
      if (coords) {
        handleMarker(new google.maps.LatLng(+coords[0], +coords[1]))
        goToPosition(new google.maps.LatLng(+coords[0], +coords[1]))
        reverseGeocoding(+coords[0], +coords[1])
      }
    }
  }

  const updatePopup = () => {
    if (!markerRef || !markerRef.current) return;
    setCurrentDisplayAddress(googlePlaceRef.current)
  }

  const validateCoords = (value) => {
    if (!value) return false;
    let splitVal = value.split(',');
    if (!splitVal || splitVal.length < 2) splitVal = value.split(' ');
    if (!splitVal || splitVal.length < 2 || !splitVal[0] || !splitVal[1])
      return false;
    if (
      splitVal[0] > -90 &&
      splitVal[0] < 90 &&
      splitVal[1] > -180 &&
      splitVal[1] < 180
    )
      return splitVal;
    return false;
  }

  const reverseGeocoding = (latitude, longitude) => {
    if (!google) throw "Google Maps not initialised";
    if (!googleGeocoderRef || !googleGeocoderRef.current) googleGeocoderRef.current = new google.maps.Geocoder();
    googleGeocoderRef.current.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      setSearchingLocation(false);
      if (status === "OK") {
        if (results[0]) { // take the first result
          googlePlaceRef.current = results[0];
          // console.log(results[0])
          setPlace(results[0]);
          updatePopup()
        } else {
          alert("No results found");
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  }


  //pan map to latlng
  const goToPosition = (pos) => {
    if (brand.googlemaps.markerZoomLevel) mapRef.current.setZoom(brand.googlemaps.markerZoomLevel);
    mapRef.current.panTo(pos);

    let topEdgeOffset;
    if (settings.pageHeight < 600) {
      topEdgeOffset = settings.pageHeight * 0.05;
    } else {
      topEdgeOffset = settings.pageHeight * 0.1;
    }
    if (settings.isMobile) {
      topEdgeOffset = settings.pageHeight * 0.25;
    }
    mapRef.current.panBy(0, -topEdgeOffset);

  }

  const handleMarker = (pos, icon) => {
    if (!markerRef || !markerRef.current) {
      markerRef.current = new google.maps.Marker({
        position: pos,
        map: mapRef.current,
        optimized: false,
        draggable: true,
        icon: {
          url: '/markerRed.svg',
          scaledSize: new google.maps.Size(30, 37),
          size: new google.maps.Size(30, 37),
          anchor: new google.maps.Point(15, 37)
        },
      });
      if (!settings.isMobile) {
        markerRef.current.addListener('click', function () {
          updatePopup()
        });
        markerRef.current.addListener('dragstart', function () {
          updatePopup()
          // hidePopup();
          // hideTowerPopup();
        });
        markerRef.current.addListener('dragend', function () {
          reverseGeocoding(markerRef.current.position.lat(), markerRef.current.position.lng())
          goToPosition(new google.maps.LatLng(markerRef.current.position.lat(), markerRef.current.position.lng()));
        });
      }
    } else {
      markerRef.current.setPosition(pos)
    }
    if (icon) markerRef.current.setIcon(icon)

    // goToPosition(pos)
    // handlePopup(pos, <DefaultMarkerContent layers={layers} />)
  }

  const handleMapLoad = (newMap) => {
    if (!mapRef || !mapRef.current) mapRef.current = newMap

    if (props.handleMapLoad) props.handleMapLoad()

    const tmpOptions = googleMapOptions
    tmpOptions.mapTypeControlOptions = {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
      mapTypeIds: [
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.HYBRID,
        google.maps.MapTypeId.TERRAIN,
        google.maps.MapTypeId.SATELLITE,
      ],
    }
    tmpOptions.zoomControlOptions = {
      position: google.maps.ControlPosition.TOP_RIGHT,
    }
    setGoogleMapOptions(tmpOptions)

    let polyOptions = {
      strokeWeight: defaultGeomStyling.strokeWeight,
      fillColor: defaultGeomStyling.fillColor,
      fillOpacity: defaultGeomStyling.fillOpacity,
      editable: true,
      draggable: true,
      zIndex: 1,
    }

    drawingManager.current = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          // google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
          // google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      // circleOptions: polyOptions,
      polygonOptions: polyOptions,
      // rectangleOptions: polyOptions,
      map: mapRef.current
    })

    google.maps.event.addListener(drawingManager.current, 'overlaycomplete', function (e) {
      // Switch back to non-drawing mode after drawing a shape.
      drawingManager.current.setDrawingMode(null)
      // Add an event listener that selects the newly-drawn shape when the user
      // mouses down on it.
      clearSelection()
      var newShape = e.overlay
      newShape.properties = {}
      newShape.style = { ...defaultGeomStyling }
      newShape.attributes = {}
      newShape.ui_config = {}
      // newShape.properties.color = defaultGeomStyling.fillColor
      newShape.properties.id = Utils.uuid()
      newShape.properties.type = e.type
      // newShape.properties.schema = { ...defaultSchema }
      newShape.properties.details = getDefaultDetails()//JSON.parse(JSON.stringify(defaultSchema))
      newShape.properties.details.name.value = e.type
      newShape.properties.newlyAdded = true

      addFeatureListeners(newShape)

      validateFeature(newShape)

      addFeatureModal(newShape)
      // addFeature(newShape)
      // if (!newShape.properties.invalid)
      //   onFeatureEdit(newShape)
      // else {
      //   setSelection(newShape)
      //   // onInvalidFeature(newShape)
      // }

    })
    try {
      google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection)
    } catch (err) {
      console.log()
    }

    if (!clientBoundariesLoaded)
      addClientBoundariesToMap(data.boundaries)

  }

  const getDefaultDetails = () => {
    let schema = {}
    Object.keys(defaultSchema).forEach(k => {
      schema[k] = { value: defaultSchema[k].value }
    })
    return schema
  }

  const validateFeature = (newShape) => {

    if (!newShape.properties.newlyAdded) newShape.properties.editing = true
    if (!newShape.properties.category) newShape.properties.category = 'no category'
    if (!Utils.validFeature(newShape.getGeoJSON())) {
      newShape.properties.invalid = true
      addInvalidFeature(newShape)
      setShapeColor(newShape)
      onInvalidFeature(newShape)
    } else {
      if (newShape.properties.invalid) delete newShape.properties.invalid
      if (newShape.properties.newlyAdded) addFeatureModal(newShape)

      addEditingFeature(newShape)
      checkInvalidFeature(newShape)
      checkEditingFeature(newShape)
      setShapeColor(newShape)
    }
  }

  const clearSelection = () => {
    if (selectedShape.current) {
      selectedShape.current.setEditable(false)
      setShapeColor(selectedShape.current)
      selectedShape.current = null
      setSelectedShapeState(null)
    }
  }

  const setSelection = (newFeature) => {
    clearSelection()
    selectedShape.current = newFeature
    if (!selectedShape.current.backupPath) {
      selectedShape.current.backupPath = Utils.getFeaturePath(newFeature)
    }
    setSelectedShapeState(selectedShape.current)
    selectedShape.current.setEditable(true)
    selectedShape.current.setDraggable(true)

    setSelectionVisuals()
  }

  const setSelectionVisuals = () => {
    if (selectedShape.current.set) selectedShape.current.set('strokeWeight', selectedShape.current.style.strokeWeight + 2)
    else if (mapRef.current.data && mapRef.current.data.setStyle) {
      mapRef.current.data.setStyle(f => {
        return ({
          strokeWeight: selectedShape.current.style.strokeWeight + 2
        })
      })
    }
  }

  const addInvalidFeature = newFeature => {
    if (invalidFeatures.filter(f => f.properties.id === newFeature.properties.id).length > 0) return

    let tmp = invalidFeatures
    tmp.push(newFeature)
    setInvalidFeatures(tmp)
  }

  const addEditingFeature = newFeature => {
    if (invalidFeatures.filter(f => f.properties.id === newFeature.properties.id).length > 0) return

    let tmp = invalidFeatures
    tmp.push(newFeature)
    setEditingFeatures(tmp)
  }

  const checkInvalidFeature = newFeature => {
    let tmp = invalidFeatures.filter(f => f.properties.id === newFeature.properties.id)
    if (!tmp || tmp.length === 0 || newFeature.properties.invalid) return

    let tmp2 = []
    invalidFeatures.forEach(f => {
      if (f.properties.id !== newFeature.properties.id || newFeature.properties.invalid) tmp2.push(f)
    })
    setInvalidFeatures(tmp2)
    console.log('invalidFeatures', tmp2)
  }

  const checkEditingFeature = newFeature => {
    let tmp = editingFeatures.filter(f => f.properties.id === newFeature.properties.id)
    if (!tmp || tmp.length === 0 || newFeature.properties.editing) return

    let tmp2 = []
    editingFeatures.forEach(f => {
      if (f.properties.id !== newFeature.properties.id || newFeature.properties.editing) tmp2.push(f)
    })
    setEditingFeatures(tmp2)
    console.log('EditingFeatures', tmp2)
  }

  // const validFeature = (newFeature) => {
  //   let intersections = turf.kinks(newFeature.getGeoJSON())
  //   return intersections && intersections.features && intersections.features.length > 0 ? false : true
  // }

  const addFeatureModal = (newFeature) => {
    if (newFeature.properties.invalid) {
      setSelection(newFeature)
      return
    }
    setSelection(newFeature)
    if (newFeature.properties.newlyAdded)
      delete newFeature.properties.newlyAdded
    newFeature.properties.centroid = getFeatureCentroid(newFeature)
    setModalAddOpen(true)
  }

  const handleAddModalClose = () => {
    setModalAddOpen(false)
    if (selectedShape.current)
      deleteFeatureLocal(selectedShape.current)
    clearSelection()
  }

  const handleAddModelSave = async (details) => {
    try {
      // selectedShape.current.ui_config = details
      selectedShape.current.properties.details = details

      validateFeatureDetails(selectedShape.current.properties.details, selectedShape.current.properties.id)

      let geojson = selectedShape.current.getGeoJSON()

      onLoading(true)
      let response = await DataController.AddNewFeatures(user.details.token, DataController.ToAPIFeature(geojson))
      onLoading(false)
      if (response && response.success) {
        // addFeature(selectedShape.current)
        deleteFeatureLocal(selectedShape.current) //remove local copy
        await resetData()

        onSuccess('Boundary added')
        setModalAddOpen(false)
      } else {
        deleteFeatureLocal(selectedShape.current)
        clearSelection()
        onError('Boundary could not be added')
      }
    } catch (e) {
      onLoading(false)
      // handleModalAddClose()
      // deleteFeatureLocal(selectedShape.current)
      // clearSelection()
      onError(e.message)
    }
  }
  const getClientBoundaries = async () => {
    if (!user.details.token) return

    let value = user.details.clientName ?? user.details.token
    let clientBoundaries = await DataController.GetClientBoundaries(value)
    console.log('!!!!!!!!!!!!!!!!! clientBoundaries', clientBoundaries)
    if (clientBoundaries && clientBoundaries.length > 0) {
      return clientBoundaries
    } else {
      onError(clientBoundaries.message)
    }
  }

  const addFeature = (newFeature) => {
    try {
      setModalAddOpen(false)
      if (!newFeature || !newFeature.properties.details.name.value) {
        newFeature.setMap(null)
        throw new Error('Invalid Polygon: Invalid Name')
      }

      validateFeatureDetails(newFeature.properties.details, newFeature.properties.id)

      let currFeatures = allFeatures.current
      // newFeature.properties.centroid = getFeatureCentroid(newFeature)
      currFeatures.push(newFeature)
      setAllFeatures(currFeatures)
    } catch (e) {
      onError(e.message)
    }
  }

  const getFeatureCentroid = (newFeature) => {
    let centroid = turf.centroid(newFeature.getGeoJSON())
    return centroid.geometry.coordinates
  }

  const updateFeature = async (featureDetails, updatedFeature = selectedShape.current) => {
    if (!updatedFeature && (!selectedShape.current || !selectedShape.current.properties.id)) throw new Error('Invalid or No Boundary')

    let currFeatures = allFeatures.current.filter(f => f.properties.id === updatedFeature.properties.id)
    if (!currFeatures || currFeatures.length === 0) return

    try {
      let geojson = updatedFeature.getGeoJSON()
      geojson.properties.details = featureDetails
      onLoading(true)
      let response = await DataController.UpdateFeatures(user.details.token, DataController.ToAPIFeature(geojson))
      onLoading(false)
      if (response && response.success) {
        currFeatures[0] = feature
        await resetData()
        onSuccess('Boundary Updated')
      } else {
        throw new Error('Oops! Something went wrong, could not delete feature. Please try again')
      }
    } catch (e) {
      console.error(e)
      onError(e.message)
    }
  }

  const resetData = async () => {
    clearSelection()
    handleUpdateModalClose()
    let newData = { ...data }
    newData.boundaries = await getClientBoundaries() // reload all the geometries, this is to get the ID as without refresh one cannot edit boundary
    dispatchData({
      type: SET_DATA,
      payload: newData
    })
  }

  const displaySelectionPopup = () => {
    setUpdateModalOpen(true)
    if (!selectedShape.current.properties.centroid) {
      selectedShape.current.properties.centroid = getFeatureCentroid(selectedShape.current)
    }

    // handlePopup(new google.maps.LatLng(selectedShape.current.properties.centroid[1], selectedShape.current.properties.centroid[0]),
    //     <DynamicLabels
    //       schema={ defaultSchema}
    //       details={selectedShape.current ? selectedShape.current.properties.details : defaultSchema}
    //     />
    // )
  }

  const deleteFeatureLocal = (newFeature) => {
    if (!feature) return
    newFeature.setMap(null)
    setAllFeatures(allFeatures.current.filter(f => f.properties.id !== newFeature.properties.id))
    setAllFeatures(allFeatures.current.filter(f => f.properties.id !== newFeature.properties.id))
  }

  const deleteCategoryFeatures = async () => {
    if (!selectedCategory) return

    try {
      onLoading(true)
      clearSelection()
      let deleteFeatures = allFeatures.current.filter(f => f.properties.details.category.value === selectedCategory)
      if (!deleteFeatures || deleteFeatures.length === 0) return

      let notDeleted = []
      for (let f of deleteFeatures) {
        let response = await DataController.DeleteFeature(user.details.token, { id: f.properties.id })
        if (response && response.success) {
          deleteFeatureLocal(f)
        } else {
          notDeleted.push(f.properties?.details?.name?.value)
        }
      }

      if (notDeleted.length > 0) {
        throw new Error(`The following features could not be deleted: ${notDeleted.join(',')}`)
      }

      onSuccess(`${selectedCategory} deleted`)
      setSelectedCategory(null)
      setDeleteCategoryConfirmation(false)
      onLoading(false)

    } catch (err) {
      onLoading(false)
      onError(err.message)
    }
  }

  const deleteFeature = async (newFeature = selectedShape.current) => {
    if (!feature) return

    try {
      onLoading(true)
      let response = await DataController.DeleteFeature(user.details.token, { id: newFeature.properties.id })
      onLoading(false)
      if (response && response.success) {
        deleteFeatureLocal(newFeature)
        clearSelection()
        setDeleteConfirmation(false)
        onSuccess('Boundary deleted')
      } else {
        throw new Error('Oops! Something went wrong, could not delete feature. Please try again')
      }
    } catch (err) {
      onLoading(false)
      onError(err.message)
    }

  }

  const setShapeColor = (shape = selectedShape.current) => {
    if (!shape) return
    let fillColor = shape.style.fillColor
    let strokeColor = shape.style.strokeColor

    if (shape.properties.invalid) {
      fillColor = shape.style.errorColor
      strokeColor = shape.style.errorColor
    } else if (shape.properties.editing) {
      fillColor = defaultGeomStyling.editColor
      strokeColor = defaultGeomStyling.editColor
    }

    if (shape.set) {
      shape.set('fillColor', fillColor)
      shape.set('strokeColor', strokeColor)
      shape.set('strokeWeight', shape.style.strokeWeight)
    }
    else if (mapRef.current.data && mapRef.current.data.setStyle)
      mapRef.current.data.setStyle(f => {
        return ({
          fillColor: fillColor,
          strokeColor: strokeColor,
          strokeWeight: shape.style.strokeWeight
        })
      })
  }

  const setHover = (type, shape) => {
    if (!type || !shape) return
    let width = shape.style.strokeWeight
    if (selectedShape && selectedShape.current && shape === selectedShape.current) width += 1
    else if (type === 'over') width += 1
    if (shape.set) {
      shape.set('strokeWeight', width)
    } else if (mapRef.current.data && mapRef.current.data.setStyle) {
      mapRef.current.data.setStyle(feature => {
        return ({
          strokeWeight: width
        })
      })
    }
    // setShapeColor(shape)
  }

  const handleMapClick = (lat, lng) => {
    clickedLocation.current = { lat: lat, lng: lng }
    clearSelection()
  }

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 27:
        clearSelection()
        break
      default:
        break
    }
  }

  const handlePopup = (position, content) => {
    if (!mapRef || !mapRef.current) throw Error('Google Map not initialised')
    if (!position) throw Error('Invalid or no position')

    if (!popupRef || !popupRef.current)
      popupRef.current = new google.maps.InfoWindow({
        content: ReactDOMServer.renderToStaticMarkup(content),
        maxWidth: '400px',
      })
    else if (content)
      popupRef.current.setContent(ReactDOMServer.renderToStaticMarkup(content))

    popupRef.current.setPosition(position)
    popupRef.current.open(mapRef.current)
  }

  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false)
  }

  const validateFeatureDetails = (details, id) => {
    if (!details.name.value) throw new Error('Invalid Polygon: Invalid name')
    if (!details.type.value) throw new Error('Invalid Polygon: Invalid boundary type')
    if (!id) throw new Error('Invalid Polygon: Invalid feature')
    let existingBoundary = allFeatures.current.filter(f => f.properties.details.name.value === details.name.value && f.properties.id !== id && f.properties.details.category.value === details.category.value)
    if (existingBoundary && existingBoundary.length > 0) {
      throw new Error(`Invalid: '${details.name.value}' already exists in the '${details.category.value}' group`)
    }
  }

  const handleUpdateModelSave = (details) => {
    // selectedShape.current.ui_config = details
    try {
      validateFeatureDetails(details, selectedShape.current.properties.id)
      // selectedShape.current.properties.details = details

      // updateFeature(selectedShape.current)
      // let updateObj = Object.assign({}, selectedShape.current)
      // updateObj.properties.details = details
      updateFeature(details)
      // handleUpdateModalClose()
    } catch (e) {
      onError(e.message)
    }
  }

  const onFeatureSelect = (value) => {
    setSelection(value)
  }

  const onInvalidFeature = (value) => {
    if (!value.properties.invalid) return
    onError('Invalid Polygon: Self-intersecting')
  }

  const onFeatureEdit = (value) => {
    setSelection(value)

    if (value.properties.type === 'polygon') {
      // console.log('!! Path', value.getGeoJSON())
      // addPolygonFeature(value.getGeoJSON())
    }
    // else if (value.properties.type === 'rectangle')
    //   console.log('!! Path', value.getBounds())
    // else if (value.properties.type === 'circle') {
    //   console.log('!! Path', value.getRadius())
    //   console.log(`!! Path Center ${value.center.lat()}, ${value.center.lng()}`)
    // }

    displaySelectionPopup()
  }

  const onFeatureDelete = (value) => {
    setSelection(value)
    setDeleteConfirmation(true)
  }

  const onDeleteCategory = (value) => {
    setSelectedCategory(value)
    setDeleteCategoryConfirmation(true)
  }

  const onFeatureZoom = (value) => {
    if (!value) return
    if (mapRef && mapRef.current) mapRef.current.fitBounds(value.getBounds());
  }

  const onFeatureSave = (feature) => {
    if (!feature) return

    feature.backupPath = Utils.getFeaturePath(feature)
    delete feature.properties.invalid
    delete feature.properties.editing
    setShapeColor(feature)
    setSelection(feature)

    updateFeature(feature.properties.details, feature)
  }

  const onFeatureCancel = (feature) => {
    if (feature.backupPath) {
      feature.setPaths(feature.backupPath)
      addFeaturePathListeners(feature)
      // delete feature.backupPath
      feature.properties.invalid = false
      feature.properties.editing = false
      onWarning('Feature edit canceled')
    }
    delete feature.properties.invalid
    delete feature.properties.editing
    setShapeColor(feature)
  }

  const addPolygonFeature = (geojson) => {
    // if (!geojson || !geojson.geometry || !geojson.geometry.type || geojson.geometry.type.toLowerCase() !== 'polygon' || !geojson.geometry.coordinates || geojson.geometry.coordinates.length === 0)
    //   return //throw new Error({ code: 400, message: 'Invalid Geometry' })
    if (!Utils.validateGeojson(geojson)) return
    if (!google) return

    let newFeature = new google.maps.Polygon({
      paths: parseGeoJsonCoordsToGoogleCoords(geojson.geometry.coordinates),
      strokeColor: geojson.style && geojson.style.strokeColor ? geojson.style.strokeColor : defaultGeomStyling.strokeColor,
      strokeWeight: geojson.style && geojson.style.strokeWeight ? geojson.style.strokeWeight : defaultGeomStyling.strokeWeight,
      fillColor: geojson.style && geojson.style.fillColor ? geojson.style.fillColor : defaultGeomStyling.fillColor,
      fillOpacity: geojson.style && geojson.style.fillOpacity ? geojson.style.fillOpacity : defaultGeomStyling.fillOpacity,
    })

    try {
      newFeature.setMap(mapRef.current)
      newFeature.properties = {}

      if (!geojson.properties || Object.keys(geojson.properties).length === 0) {
        // newFeature.id = Utils.uuid()
        newFeature.properties.id = Utils.uuid()
        newFeature.properties.type = geojson.geometry.type
        newFeature.properties.schema = JSON.parse(JSON.stringify(defaultSchema))
        newFeature.properties.details = JSON.parse(JSON.stringify(defaultSchema))
        newFeature.properties.details.name.value = geojson.geometry.type
        newFeature.properties.details.category.value = geojson.properties.category ?? defaultSchema.category.value
        newFeature.style = { ...defaultGeomStyling }
      } else {
        // newFeature.id = geojson.properties.id
        newFeature.properties.id = geojson.properties.id ?? Utils.uuid()
        newFeature.properties.type = geojson.properties.type ?? geojson.geometry.type
        newFeature.properties.schema = geojson.ui_config ?? JSON.parse(JSON.stringify(defaultSchema))
        newFeature.properties.details = geojson.properties.details ?? JSON.parse(JSON.stringify(defaultSchema))

        if (geojson.properties.details && geojson.properties.details.name && geojson.properties.details.name.value)
          newFeature.properties.details.name.value = geojson.properties.details.name && geojson.properties.details.name.value ? geojson.properties.details.name.value : geojson.geometry.type
        else {
          if (!newFeature.properties.details) newFeature.properties.details = {}
          if (!newFeature.properties.details.name) newFeature.properties.details.name = { value: '' }
          newFeature.properties.details.name.value = geojson.geometry.type
        }
        if (geojson.properties.details && geojson.properties.details.category && geojson.properties.details.category.value)
          newFeature.properties.details.category.value = geojson.properties.details.category && geojson.properties.details.category.value ? geojson.properties.details.category.value : defaultSchema.category.value
        else {
          if (!newFeature.properties.details.category) newFeature.properties.details.category = { value: '' }
          newFeature.properties.details.category.value = geojson.properties.category
        }

        newFeature.style = { ...defaultGeomStyling }
        if (geojson.style) {
          newFeature.style.fillColor = geojson.style.fillColor ?? defaultGeomStyling.fillColor
          newFeature.style.strokeColor = geojson.style.fillColor ?? defaultGeomStyling.strokeColor
        }

      }

      addFeatureListeners(newFeature)

      addFeature(newFeature)

    } catch (err) {
      console.error(err)
      newFeature.setMap(null)
    }
  }

  const addFeatureListeners = (feature) => {
    addFeaturePathListeners(feature)
    addFeatureMouseListeners(feature)
  }

  const addFeaturePathListeners = (feature) => {
    feature.getPaths().forEach(function (path, index) {

      google.maps.event.addListener(path, 'insert_at', function () {
        validateFeature(feature)
      })

      google.maps.event.addListener(path, 'remove_at', function () {
        validateFeature(feature)
      })

      google.maps.event.addListener(path, 'set_at', function () {
        validateFeature(feature)
      })

    })
  }

  const addFeatureMouseListeners = (feature) => {

    google.maps.event.addListener(feature, 'click', function (e) {
      setSelection(feature)
    })
    google.maps.event.addListener(feature, 'mouseover', function () {
      setHover('over', feature)
    })
    google.maps.event.addListener(feature, 'mouseout', function () {
      setHover('out', feature)
    })
  }

  const parseGeoJsonCoordsToGoogleCoords = coordinates => {
    if (!coordinates || coordinates.length === 0) return
    let coords = []
    coordinates.forEach(c => {
      c.forEach(cc => {
        coords.push({ lat: cc[1], lng: cc[0] })
      })
    })
    return coords
  }

  const addGeoJson = (geojson) => {
    if (!geojson) return
    mapRef.current.data.addGeoJson(geojson)
    mapRef.current.data.addListener('click', function (e) {
      if (!e.feature) return
      console.log('!! shape clicked', e.feature)
      setSelection(e.feature)
    })
    mapRef.current.data.addListener('mouseover', function (e) {
      setHover('over', e.feature)
    })
    mapRef.current.data.addListener('mouseout', function (e) {
      setHover('out', e.feature)
    })
  }

  const onError = (message) => {
    setError(message)
  }
  const onSuccess = (message) => {
    setSuccess(message)
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setError(false)
  }

  const onWarning = (message) => {
    setWarning(message)
  }

  const onLoading = (value) => {
    setLoading(value)
  }

  const handleWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setWarning(false)
  }

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSuccess(false)
  }

  const onClientChange = async (client) => {
    try {

      // setAllFeatures([])
      clearSelection()
      allFeatures.current.forEach(f => {
        deleteFeatureLocal(f)
      })

      let newUser = { ...user }
      newUser.details.token = client.token
      newUser.details.selectedClient = client
      newUser.details.clientFullName = client.full_name

      dispatchUser({
        type: SET_USER,
        payload: newUser
      })

      let newData = { ...data }
      data.boundaries = []
      dispatchData({
        type: SET_USER,
        payload: newData
      })
      // await getClientSubscriptions(client.token)
      // await getClientBoundaries(client.token)
    } catch (e) {
      console.error('!! Client change', e)
      onError("Could not Change Client")
    }
  }
  const doMigration = async () => {
    if (!migrationData) return onError('Invalid destination or source')

    let source = data.environments.filter(e => e.value === migrationData.source.value)
    let destination = data.environments.filter(e => e.value === migrationData.destination.value)
    let revertMigration = migrationData.revert.value

    if (!source || source.length === 0) return onError('Invalid source ')
    if (!destination || destination.length === 0) return onError('Invalid destination')

    if (!revertMigration) {
      if (!allFeatures.current || allFeatures.current.length == 0) return onError('You cannot migrate as there are no boundaries on source')

      let newFeatures = []
      allFeatures.current.forEach(f => {
        newFeatures.push(DataController.ToAPIFeature(f.getGeoJSON()))
      })

      let featureCollection = {
        type: 'FeatureCollection',
        features: newFeatures
      }

      onLoading(true)
      let response = await DataController.MigrateBoundaries(user.details.token, { source: source[0], destination: destination[0] }, featureCollection)
      onLoading(false)
      if (response && response.success) {
        onSuccess('Boundaries migrated to ' + destination[0].title)
        handleMigrateModelClose()
      } else {
        clearSelection()
        onError('Boundary could not be migrated')
      }

    } else {
      onLoading(true)
      let response = await DataController.RevertMigrateBoundaries(user.details.token, destination[0])
      onLoading(false)
      if (response && response.success) {
        onSuccess('Migration reverted to previous state for ' + destination[0].title)
        handleMigrateModelClose()
        await resetData()
      } else {
        clearSelection()
        onError('Boundary could not be migrated')
      }
    }

  }

  const handleMigrateModelSave = (details) => {
    setMigrationData(details)
    setMigrateOpen(true)
  }

  const handleMigrateModelClose = () => {
    setMigrationData(null)
    setMigrateOpenModal(false)
    setMigrateOpen(false)
  }

  const onSpatialFileUpload = (features) => {
    console.log(features)
  }

  const handleImportProcessComplete = async (importFeatures) => {
    console.log('importFeatures', importFeatures)
    if (!importFeatures || importFeatures.length === 0) throw "No valid features to import"

    try {
      //validate each feature
      let newFeatures = []
      importFeatures.forEach(f => {
        validateFeatureDetails(f.properties.details, f.properties.id)
        newFeatures.push(DataController.ToAPIFeature(f))
      })
      // setAllFeatures([...currFeatures, ...newFeature])

      let featureCollection = {
        type: 'FeatureCollection',
        features: newFeatures
      }

      onLoading(true)
      let response = await DataController.AddNewFeatures(user.details.token, featureCollection)
      onLoading(false)
      if (response && response.success) {
        await resetData()
        onSuccess('Successfully imported new boundaries')
        setUploadStartOpen(false)
      } else {
        onError('Could not import new boundaries: ' + response.errorReason)
      }

    } catch (e) {
      onLoading(false)
      onError(e.message)
    }
  }

  return (
    <div className={brand.navbar.show && brand.fullHeight ? classes.rootNav : classes.root}>
      {
        (brand.loadingSpinner.show && loading) &&
        <Loader />
      }
      <Grid container className={classes.userControls}>
        <Grid container justify='start' item className={classes.autocompleteContainer}>
          <GoogleAutocompleteWidget
            noLoad={true}
            id='pac-input'
            placeholder='Enter an address or XY (latitude,longitude)'
            // disableGeolocation={disableGeolocation}
            handleAddressChange={onAddressChange}
            autocomplete={googleMapOptions.autocomplete}
            // handleGeolocate={geolocate}
            loadingLocation={searchingLocation}
            place={place}
            // place={googlePlaceRef.current}
            displayAddress={currentDisplayAddress}
          />
        </Grid>
        <Grid container justify='start' item >
          <GroupFeaturesList
            // loading={loading}
            // clients={data.clients}
            // selectedClient={user.details.selectedClient}
            // onClientChange={onClientChange}
            // title={'Features'}
            title={'Campaigns'}
            selectedFeature={selectedShapeState}
            details={features}
            onSelect={onFeatureSelect}
            onEdit={onFeatureEdit}
            onDelete={onFeatureDelete}
            onDeleteCategory={onDeleteCategory}
            onZoom={onFeatureZoom}
            onSave={onFeatureSave}
            onCancel={onFeatureCancel}
            onUpload={() => setUploadStartOpen(true)}
          />
        </Grid>
      </Grid>

      <DynamicFormDialog
        loading={loading}
        open={modalAddOpen}
        handleClose={handleAddModalClose}
        handleSave={handleAddModelSave}
        onError={onError}
        // schema={selectedShape.current ? selectedShape.current.properties.schema : defaultSchema}
        schema={JSON.parse(JSON.stringify(defaultSchema))}
        details={selectedShape.current ? selectedShape.current.properties.details : null}
      />

      <DynamicFormDialog
        loading={loading}
        open={updateModalOpen}
        handleClose={handleUpdateModalClose}
        handleSave={handleUpdateModelSave}
        onError={onError}
        // schema={selectedShape.current ? selectedShape.current.properties.schema : defaultSchema}
        schema={JSON.parse(JSON.stringify(defaultSchema))}
        details={selectedShape.current ? selectedShape.current.properties.details : null}
      />

      <DynamicFormDialog
        loading={loading}
        open={migrateOpenModal}
        handleClose={(e) => handleMigrateModelClose()}
        handleSave={handleMigrateModelSave}
        onError={onError}
        schema={JSON.parse(JSON.stringify(migrationSchema))}
        details={JSON.parse(JSON.stringify(migrationSchema))}
        title={'Boundary Migration'}
      />

      <GoogleMap
        className={classes.googleMaps}
        apikey={brand.googlemaps.apikey}
        options={googleMapOptions}
        handleMapClick={handleMapClick}
        onMapLoad={handleMapLoad}
        autocompleteInput='pac-input'
        onAddressChange={onAddressChange}
        addMapClickListener={!settings.isMobile}
        setIsLoading={setLoading}
        libraries={['places', 'drawing', 'geometry']}
      >

        {/* {(selectedShape.current && selectedShape.current.properties.centroid) &&
          <MarkerContainer map={mapRef.current} position={new google.maps.LatLng(selectedShape.current.properties.centroid[1], selectedShape.current.properties.centroid[0])}>
            <DynamicForm
              open={modalOpen}
              handleClose={handleModalClose}
              handleSave={handleModelSave}
              schema={selectedShape.current ? selectedShape.current.properties.schema : defaultSchema}
              details={selectedShape.current ? selectedShape.current.properties.details : null}
            />
          </MarkerContainer>
        } */}

      </GoogleMap>

      {/* <Button variant="contained" color="secondary" className={classes.uploadBtn} onClick={(e) => setUploadStartOpen(true)}>
        Upload
      </Button> */}

      <Button variant="contained" color="primary" className={classes.migrateBtn} onClick={(e) => setMigrateOpenModal(true)}>
        Migration Tool
      </Button>

      <Dialog
        loading={loading}
        open={deleteConfirmation}
        onClose={() => { setDeleteConfirmation(false) }}
        aria-labelledby="delete-confirmation-dialog-title"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-dialog-description">
            You are about to delete this feature <b>{(selectedShape.current && selectedShape.current.details && selectedShape.current.details.name) && selectedShape.current.details.name.value ? `: ${selectedShape.current.details.name.value}` : ''}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setDeleteConfirmation(false) }} disabled={loading} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => deleteFeature()} disabled={loading} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        loading={loading}
        open={deleteCategoryConfirmation}
        onClose={() => { setDeleteCategoryConfirmation(false); setSelectedCategory(null) }}
        aria-labelledby="delete-category-confirmation-dialog-title"
        aria-describedby="delete-category-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog-title">Delete &quot;{selectedCategory}&quot;</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-category-confirmation-dialog-description">
            You are about to delete <b>all the features</b> that is associated with <b>{selectedCategory || ''}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setDeleteCategoryConfirmation(false); setSelectedCategory(null) }} disabled={loading} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => deleteCategoryFeatures()} disabled={loading} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        loading={loading}
        open={migrateOpen}
        onClose={() => { setMigrateOpen(false) }}
        aria-labelledby="migrate-dialog-title"
        aria-describedby="migrate-dialog-description"
      >
        <DialogTitle id="migrate-dialog-title">Boundary Migration</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-dialog-description">
            {(migrationData && migrationData.revert && migrationData.revert.value) ?
              <>Revert migration to previous data
                <br /><br />
                ** Please note using this feature will <b className={classes.important}>remove and replace</b> all the current data on the destination environment with the previous data before the migration started
              </>
              :
              <>Migrate all the boundaries from one environment to another.<br /><br />
                ** Please note using this feature will <b className={classes.important}>remove and replace</b> all the current data on the destination environment with this environment&apos;s data.</>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setMigrateOpen(false) }} disabled={loading} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => doMigration()} disabled={loading} color="secondary" autoFocus>
            {(migrationData && migrationData.revert && migrationData.revert.value) ? "Revert" : "Migrate"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Start File Upload Process Modal*/}
      {uploadStartOpen && <Container style={{ position: 'absolute' }}>
        <DataImportProcessFlow
          loading={loading}
          open={uploadStartOpen}
          onClose={() => { setUploadStartOpen(false) }}
          schema={JSON.parse(JSON.stringify(defaultSchema))}
          style={JSON.parse(JSON.stringify(defaultGeomStyling))}
          onComplete={handleImportProcessComplete} />
      </Container>}

      <Snackbar open={error} autoHideDuration={2000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleErrorClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={warning} autoHideDuration={2000} onClose={handleWarningClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleWarningClose} severity="warning">
          {warning}
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={2000} onClose={handleSuccessClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleSuccessClose} severity="success">
          {success}
        </Alert>
      </Snackbar>

    </div>
  )
}