import React, { useContext, useEffect, useRef, useState } from 'react'

import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Step, StepLabel, Stepper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert';
// import { DataGrid } from '@material-ui/data-grid';
import { DataGrid } from '@mui/x-data-grid';


import { BrandContext } from '../context/BrandContext/context'
import Utils from '../utils/Utils'
import DynamicForm from './Dialogs/DynamicForm';
import { LabelImportant } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    uploadBtn: {
        // position: 'absolute',
        right: '10pt',
        bottom: '18pt',
    },
    important: {
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
    },
    importantItem: {
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
        padding: '0px 5px'
    },
    'invalid-feature': {
        backgroundColor: '#ed0202'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    flowContentContainer: {
        width: '600px'
    },
    stepperRoot: {
        padding: '0px'
    }
}))

function Alert(props) {
    return <MuiAlert elevation={1} variant="filled" {...props} />;
}


function Step1(props) {
    const classes = useStyles()

    return (
        <div>
            <>
                Before you start the upload process, there are a few things to take note of: <br />
                <br />
                <List dense>
                    <ListItem>
                        <ListItemIcon><LabelImportant /></ListItemIcon>
                        <ListItemText>Each feature has to have <b className={classes.important}> a unique name </b> property.</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><LabelImportant /></ListItemIcon>
                        <ListItemText>All features will have the <b className={classes.important}>same type</b> provided (promotion, exclusion, etc.).</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><LabelImportant /></ListItemIcon>
                        <ListItemText>All features will have the <b className={classes.important}>same attributes</b> provided.</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><LabelImportant /></ListItemIcon>
                        <ListItemText>We only make use of <b className={classes.important}>polygon</b> feature types.</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><LabelImportant /></ListItemIcon>
                        <ListItemText>Any invalid feature (eg. self-intersecting polygon) <b className={classes.important}>will be omitted</b>.</ListItemText>
                    </ListItem>
                </List>
            </>
        </div>
    )
}

function Step2(props) {
    const classes = useStyles()

    const fileRef = useRef()
    const [error, setError] = useState(false)
    const [file, setFile] = useState(props.file)

    useEffect(() => {
        setFile(props.file)
    }, [props.file])

    const onChangeFile = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const uploadedJSON = e.target.files[0];
            setFile(uploadedJSON)
            if (props.onChangeFile) props.onChangeFile(uploadedJSON)
        }
    }

    return (
        <div id="upload-dialog-description">
            <>
                <b className={classes.important}>Please Note </b>
                <br />
                Each feature has to have <b className={classes.important}> a unique name </b> property.
                <br />
                We only accept the following file formats: <b className={classes.important}> geojson</b>.
            </>
            <br />
            <br />
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item>
                    <Button variant='outlined' color="secondary" component="label">
                        Select file
                        <input
                            hidden
                            accept="application/geo+json"
                            multiple={false}
                            type="file"
                            ref={fileRef}
                            onChange={onChangeFile} />
                    </Button>
                </Grid>
                <Grid item>
                    {file && <Typography variant='caption' style={{ paddingLeft: '10pt' }}>Selected File: <b>{file.name}</b> </Typography>}
                </Grid>
            </Grid>


            {error && <Alert severity="error">{error}</Alert>}
        </div>
    )
}

function Step3(props) {
    const classes = useStyles()

    return (
        <div>
            <div id="upload-dialog-description">
                {props.rows?.length > 0 ?
                    <>
                        Only <b className={classes.important}>{props.featureCount - props.rows?.length}  out of {props.featureCount} </b> features will be imported.
                        <br />
                        Here are all the invalid features loaded from the file <b className={classes.important}>{props.fileName}.</b> These features will not be imported <br />
                    </>
                    : <>
                        All <b className={classes.important}>{props.featureCount} </b> features loaded from the file <b className={classes.important}>{props.fileName} </b> are valid. <br />
                    </>}
                <br />
            </div>
            {props.rows?.length > 0 && <Box>
                <DataGrid
                    rows={props.rows}
                    columns={props.columns}
                    pageSize={30}
                    autoHeight
                    // checkboxSelection
                    disableSelectionOnClick
                    density={'compact'}
                    getRowClassName={(params) => params.row.invalid ? 'invalid-feature' : ''}
                />
            </Box>}
        </div>
    )

}

function Step4(props) {
    const classes = useStyles()

    const onChange = (details, valid) => {
        if (props.onChange) props.onChange(details, valid)
    }

    return (
        <div id="upload-dialog-description">
            <DynamicForm
                title={props.title}
                loading={props.loading}
                schema={props.schema}
                details={props.details}
                handleClose={props.handleClose}
                handleSave={props.handleSave}
                onError={props.onError}
                onChange={onChange} />
        </div>
    )
}

export default function DataImportProcessFlow(props) {
    const classes = useStyles();
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [activeStep, setActiveStep] = useState();
    const [rows, setRows] = useState([]);
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(props.loading || false);
    const [error, setError] = useState(false)
    const [proceedError, setProceedError] = useState(false)
    const [schema, setSchema] = useState(props.schema || {})
    const [featureDetails, setFeaturesDetails] = useState(false)

    const [open, setOpen] = useState(props.open || false);
    const [features, setFeatures] = useState([])
    const [validFeatures, setValidFeatures] = useState([])

    const resetState = () => {
        setActiveStepIndex(0)
        setActiveStep()
        setRows([])
        setFile()
        setLoading(props.loading || false)
        setError(false)
        setProceedError(false)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            minWidth: 150
        },
        {
            field: 'message',
            headerName: 'Message',
            editable: false,
            width: 400
        }
    ]

    const steps = [
        {
            title: 'Start',
            description: 'Information and requirements regarding the import process',
        }, {
            title: 'Upload',
            description: 'Upload your file',
        }, {
            title: 'Validation',
            description: 'Validate boundaries',
        }, {
            title: 'Attributes',
            description: 'Information and requirements regarding the import process',
        },
    ]

    useEffect(() => {
        if (activeStepIndex < steps.length && activeStepIndex >= 0)
            setActiveStep(steps[activeStepIndex])
    }, [activeStepIndex])

    useEffect(() => {
        setLoading(props.loading || false)
    }, [props.loading])

    useEffect(() => {
        setOpen(props.open || false)
    }, [props.open])

    useEffect(() => {
        let tmpSchema = { ...props.schema }
        delete tmpSchema?.name
        delete tmpSchema?.description
        setSchema(tmpSchema)
    }, [props.schema])

    useEffect(() => {
        parseUploadedFile()
    }, [file])

    useEffect(() => {
        parseFeaturesToRows()
        setValidFeatures(features?.filter(f => !f.invalid))
    }, [features])

    const renderStep = (index) => {
        console.log(props)
        switch (index) {
            case 0:
                return <Step1 />
            case 1:
                return <Step2 file={file} onChangeFile={(f) => setFile(f)} />
            case 2:
                return <Step3
                    fileName={file?.name}
                    rows={rows}
                    columns={columns}
                    featureCount={features?.length || 0} />
            case 3:
                return <Step4
                    loading={loading}
                    schema={schema}
                    details={schema}
                    onError={(e) => setError(e)}
                    onChange={onSchemaDetailsChange}
                />
            default:
                break
        }
    }

    const onSchemaDetailsChange = (details, valid) => {
        if (!valid || !Utils.toJson(JSON.stringify(details))) return setFeaturesDetails(false)

        setFeaturesDetails(details)
        addFeatureDetailsToFeatures(details)
    }

    const addFeatureDetailsToFeatures = (details) => {
        let tmpFeatures = validFeatures.map(f => {
            if (!f.properties) f.properties = {}
            f.properties.details = { ...f.properties.details, ...details }
            return f
        })
        setValidFeatures(tmpFeatures)
    }

    const handleNext = () => {
        setError(null)

        if (activeStepIndex >= 1 && !file) {
            setError('No file uploaded')
            return
        } else if (activeStepIndex === 2 && features?.filter(f => !f.invalid).length === 0) {
            setError('No valid features to import')
            return
        } else if (activeStepIndex === 4 && !featureDetails) {
            setError('Please fill in all the required details')
            return
        }

        setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setError(null)
        setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStepIndex(0);
    };

    const onClose = () => {
        setOpen(false)
        resetState()
        if (props.onClose) props.onClose()
    }

    const onComplete = () => {
        if (!featureDetails)
            return setError('Please fill in all the required details')
        else if (!featureDetails.type?.value)
            return setError('Invalid Polygon: Invalid boundary type')
        else if (!validFeatures || validFeatures.length === 0)
            return setError('No Valid Features to import')

        if (props.onComplete) props.onComplete(validFeatures)
    }

    const parseUploadedFile = () => {
        if (!file) return
        setLoading(true)

        const fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = e => {
            try {
                const target = e.target
                const result = target?.result

                setFeatures(handleGeojson(Utils.toJson(result)))

                // setUploadOpen(false)
                // setValidationOpen(true)
            } catch (e) {
                console.error('Error occurred while reading file', e)
                if (e.message) setError(e.message)
            } finally {
                setLoading(false)
            }
        }
    }

    const parseFeaturesToRows = () => {
        if (!features || features.length === 0) return

        let newRows = features.map(feature => {
            if (feature.invalid) return {
                ...feature
            }
            return {
                ...feature.properties,
                message: 'Feature is valid'
            }
        }).sort((a, b) => {
            console.log('a', a)
            // return a.invalid - b.invalid
            return (a.invalid === b.invalid) ? 0 : a.invalid ? -1 : 1
        }).filter(r => r.invalid)

        setRows(newRows)
    }

    const handleGeojson = (geojson) => {
        if (!geojson || !geojson.type) throw { code: 400, message: 'Bad Request - Invalid geojson' }
        if (geojson.type !== 'Feature' && geojson.type !== 'FeatureCollection') throw { code: 400, message: 'Bad Request - Invalid feature' }

        let features = []
        if (geojson.type === 'FeatureCollection')
            features = parseFeatureCollection(geojson)
        else if (geojson.type === 'Feature')
            features = [parseFeature(geojson)]

        let parsedFeatures = features.map(feature => {
            let invalidFeature = false
            if (!Utils.validateGeojson(feature)) {
                invalidFeature = true
                feature = {
                    id: feature.properties.id,
                    invalid: true,
                    message: 'Unsupported format',
                    name: feature.properties?.name || ''
                }
            } else if (!Utils.validFeature(feature)) {
                invalidFeature = true
                feature = {
                    id: feature.properties.id,
                    invalid: true,
                    message: 'Self intersecting feature',
                    name: feature.properties?.name || ''
                }
            } else if (!feature.properties) {
                invalidFeature = true
                feature = {
                    id: feature.properties.id,
                    invalid: true,
                    message: 'Feature does not have properties',
                    name: feature.properties?.name || ''
                }
            } else if (!feature.properties.name) {
                invalidFeature = true
                feature = {
                    id: feature.properties.id,
                    invalid: true,
                    message: 'Feature does not have a name',
                    name: feature.properties?.name || ''
                }
            }

            if (props.schema && !invalidFeature) {
                if (!feature.properties.name) {
                    invalidFeature = true
                    feature = {
                        id: feature.properties.id,
                        invalid: true,
                        message: `Feature is missing required field "name"`,
                        name: feature.properties?.name || ''
                    }
                }
            }

            if (!invalidFeature) {
                //check for duplicate names
                let existingBoundary = features.filter(f => f.properties?.name === feature.properties?.name && f.properties?.id !== feature.properties?.id)
                if (!existingBoundary && existingBoundary.length > 0) {
                    invalidFeature = true
                    feature = {
                        id: feature.properties?.id || Utils.uuid(),
                        invalid: true,
                        message: 'Duplicate feature name',
                        name: feature.properties?.name || ''
                    }
                }
            }

            if (props.defaultGeomStyling && !invalidFeature) feature.style = { ...props.defaultGeomStyling }

            return feature
        })

        return parsedFeatures
    }


    const parseFeatureCollection = (featureCollection) => {
        let features = []

        featureCollection.features.forEach(feature => {
            features.push(parseFeature(feature))
        })

        return features
    }

    const parseFeature = (geojson) => {
        let newFeature = { ...geojson }
        newFeature.properties = JSON.parse(JSON.stringify(geojson.properties || {}).toLowerCase())

        if (!geojson.properties || Object.keys(newFeature.properties).length === 0) {
            newFeature.properties.id = Utils.uuid()
            newFeature.properties.type = newFeature.geometry.type
            newFeature.properties.schema = JSON.parse(JSON.stringify(props.schema))
            newFeature.properties.details = JSON.parse(JSON.stringify(props.schema))
            newFeature.properties.details.name.value = newFeature.geometry.type
            newFeature.properties.details.category.value = newFeature.properties.category ?? props.schema.category?.value
            newFeature.style = { ...props.style }
        } else {
            newFeature.properties.id = newFeature.properties.id ?? Utils.uuid()
            newFeature.properties.type = newFeature.properties.type ?? newFeature.geometry.type
            newFeature.properties.schema = newFeature.ui_config ?? JSON.parse(JSON.stringify(props.schema))
            newFeature.properties.details = newFeature.properties.details ?? JSON.parse(JSON.stringify(props.schema))

            if (newFeature.properties.name)
                newFeature.properties.details.name.value = newFeature.properties.name
            if (newFeature.properties.details?.category && newFeature.properties.details?.category?.value)
                newFeature.properties.details.category.value = newFeature.properties.details.category && newFeature.properties.details.category.value ? newFeature.properties.details.category.value : props.schema?.category?.value
            else {
                if (!newFeature.properties.details.category) newFeature.properties.details.category = { value: '' }
                newFeature.properties.details.category.value = newFeature.properties.category
            }

            newFeature.style = { ...(props.style || {}) }
            if (newFeature.style) {
                newFeature.style.fillColor = newFeature.style.fillColor ?? props.style?.fillColor
                newFeature.style.strokeColor = newFeature.style.fillColor ?? props.style?.strokeColor
            }
        }

        return newFeature
    }

    return (
        <div className={classes.root}>
            <Dialog
                loading={loading}
                open={open}
                onClose={onClose}
                scroll={'paper'}
                aria-labelledby="upload-dialog-title"
                aria-describedby="upload-dialog-description"
            >
                <DialogTitle id="upload-dialog-title">
                    Import boundaries
                    <Divider style={{ marginBottom: '20pt' }} />
                    <Stepper
                        activeStep={activeStepIndex}
                        alternativeLabel
                        className={classes.stepperRoot}>
                        {steps.map((step) => (
                            <Step key={step.title}>
                                <StepLabel>{step.title}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </DialogTitle>
                <DialogContent dividers={true} className={classes.flowContentContainer}>
                    <div>
                        {/* {activeStep?.component} */}
                        {renderStep(activeStepIndex)}
                    </div>
                    <Box style={{ marginTop: '10pt' }}>
                        {error && <Alert severity="error">{error}</Alert>}
                    </Box>
                </DialogContent>
                <DialogActions >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Button onClick={onClose} disabled={loading} color="secondary">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <div>
                                <Button
                                    disabled={activeStepIndex === 0 || loading}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                >
                                    Back
                                </Button>
                                {activeStepIndex === steps.length - 1 ? (
                                    <Button variant="contained" onClick={onComplete} disabled={loading} color="primary" autoFocus>
                                        Import
                                    </Button>
                                ) : (
                                    <Button variant="contained" color="primary" onClick={handleNext}>
                                        Next
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}
