import React, {useContext, useEffect, useState, useRef} from 'react'

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {CheckCircleOutline, Fullscreen, HighlightOff} from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Checkbox from '@material-ui/core/Checkbox'
import StopIcon from '@material-ui/icons/Stop'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FormatShapesIcon from '@material-ui/icons/FormatShapes'

import Utils from '../../utils/Utils'
import {GithubPicker} from 'react-color'
import Palette from '@material-ui/icons/Palette'
import PaletteIcon from '@material-ui/icons/Palette'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // position: 'absolute',
        zIndex: '1'
    },
    contentRoot: {
        width: '100%',
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(1),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        // margin: theme.spacing(1),
    },
    fullWidth: {
        width: '100%'
    },
    callToAction: {
        // textAlign: 'right'
    },
    actionButtons: {
        textAlign: 'right'
    },
    actionIcons: {
        textAlign: 'left'
    }
}))

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>
const checkedIcon = <CheckBoxIcon fontSize="small"/>

export default function DynamicLabels(props) {
    const classes = useStyles()
    console.log('!!! p', props.details)

    const ref = useRef()
    const [paletteOpen, setPaletteOpen] = useState(false)
    const [details, setDetails] = useState(Object.assign({},))

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                closePalette()
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])

    const onPalette = (e, value) => {
        if (!paletteOpen) setPaletteOpen(true)
        else setPaletteOpen(false)
    }

    const closePalette = () => {
        setPaletteOpen(false)
    }

    const handleColorPicker = (value) => {
        // setColor(value.hex)
        // let t = selectedFeature
        // t.properties.color = value.hex
        // t.set('fillColor', value.hex)
        // // t.current.set('strokeWeight', 1)
        // t.set('strokeColor', value.hex)
        // setSelectedItem(t)
        let tmpDetails = {...details}
        tmpDetails['color'].value = value.hex
        setDetails(tmpDetails)
        closePalette()
        // onEdit(t)
    }

    return (
        <div
            className={classes.root}>
            <Typography variant="h6" id="form-dialog-title">{'Feature'}</Typography>
            <div className={classes.content}>
                {
                    Object.keys(props.details).map((key, i) => {
                        // let prop = details[key]
                        console.log('## details', props.details[key])
                        console.log('## schema', props.schema[key])
                        return <div key={key + '_' + i}>
                            <Typography variant={'body'}> <b> {props.schema[key].title}: </b> </Typography>
                            <Typography variant={'body'}> {props.details[key].value} </Typography>
                        </div>

                    })
                }
            </div>

            <div className={classes.callToAction}>
                {/* <IconButton className={classes.actionButton} edge="end" aria-label="style" onClick={(e) => onPalette(e, props.details)}>
                    <Palette />
                </IconButton> */}
                <div className={classes.actionIcons}>
                    <IconButton className={classes.actionButton} edge="end" aria-label="style"
                                onClick={(e) => onZoom(e, props.details)}>
                        <Fullscreen/>
                    </IconButton>
                    <IconButton className={classes.actionButton} edge="end" aria-label="style"
                                onClick={(e) => onPalette(e, props.details)}>
                        <PaletteIcon/>
                    </IconButton>
                    <IconButton className={classes.actionButton} edge="end" aria-label="delete"
                                onClick={(e) => onDelete(e, props.details)}>
                        <DeleteIcon/>
                    </IconButton>
                </div>

            </div>
        </div>
    )
}
