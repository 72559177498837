import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PaletteIcon from '@material-ui/icons/Palette';
import { CircularProgress, Collapse, Divider, ListItemIcon, Menu, MenuItem, Paper, TextField } from '@material-ui/core';
import { ReactComponent as PolygonIcon } from '../../content/polygonFill.svg'
import { ReactComponent as SquareIcon } from '../../content/squareFill.svg'
import { ReactComponent as CircleIcon } from '../../content/circleFill.svg'
import { SvgIcon } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { GithubPicker } from 'react-color';
import FeaturesListItem from './FeaturesListItem';
import { ArrowDropDown, ArrowDropUp, CategoryRounded, Close, CloudUpload, ExpandLess, ExpandMore, Filter, FilterList, Group, GroupWork, MoreVert, Person, Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { UserContext } from '../../context/UserContext/context';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: theme.spacing(1),
        height: '80vh'
    },
    title: {
        margin: theme.spacing(1),
        marginBottom: '0px',
        display: 'flex',
    },
    noItems: {
        textAlign: 'center',
        color: '#989898',
        padding: theme.spacing(2)
    },
    filter: {
        margin: '0px',
        padding: '0px',
        paddingLeft: theme.spacing(1),
    },
    filterIcon: {
        padding: theme.spacing(1)
    },
    grow: {
        flexGrow: 1
    },
    features: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '88%'
    },
    center: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    category: {
        backgroundColor: "#fbfbfb",
        borderTop: "1px solid #ededed",
        borderBottom: "1px solid #ededed"
    },
    categoryTitle: {
        fontWeight: "bold"
    },
    actionButton: {
        position: 'initial',
        zIndex: 1,
        padding: '6px'
    },
}))

export default function GroupFeaturesList(props) {
    const classes = useStyles();
    const { user } = useContext(UserContext)
    const [showFilter, setShowFilter] = useState(false)
    const [showClients, setShowClients] = useState(false)
    const [filter, setFilter] = useState('')
    const [details, setDetails] = useState(props.details ? props.details : [])
    const [categories, setCategories] = useState([])
    const [defaultCollapsed, setDefaultCollapsed] = useState(true)
    const [categoriesClosed, setCategoriesClosed] = useState([])
    const [moreAnchorEl, setMoreAnchorEl] = useState()
    const [selectedMenu, setSelectedMenu] = useState()

    // useEffect(() => {
    //     setCategories(getGroupedItems())
    // }, [details])

    useEffect(() => {
        setCategories(getGroupedItems())
        handleDefaultCollapse()
    }, [])

    useEffect(() => {
        let groups = getGroupedItems()
        setCategories([...groups])
        handleDefaultCollapse([...groups])
    }, [props.details])

    useEffect(() => {
        if (props.selectedFeature && categoriesClosed.filter(c => c === props.selectedFeature.properties.details.category.value).length > 0)
            openCategoryCollapse(props.selectedFeature.properties.details.category.value)
    }, [props.selectedFeature])

    const updateDetailsFilter = () => {
        let tmp = []
        if (filter && filter.length > 0) {
            tmp = props.details.filter(d => d.properties.details.title.value.includes(filter))
        } else {
            tmp = props.details
        }
        setDetails(tmp)
    }

    const handleDefaultCollapse = (groups) => {
        if (!groups || groups.length === 0 || !defaultCollapsed) return
        let vals = []
        groups.forEach(c => {
            vals.push(c.category)
        })
        setCategoriesClosed(vals)
        setDefaultCollapsed(false)
        handleClose()
    }

    const onSelect = (value) => {
        if (props.onSelect) props.onSelect(value)
        onZoom(value)
        handleClose()
    }

    const onEdit = (value) => {
        if (props.onEdit) props.onEdit(value)
        handleClose()
    }

    const onDelete = (value) => {
        if (props.onDelete) props.onDelete(value)
        handleClose()
    }

    const onDeleteCategory = (value) => {
        if (props.onDeleteCategory) props.onDeleteCategory(value)
        handleClose()
    }

    const onZoom = (value) => {
        if (props.onZoom) props.onZoom(value)
        handleClose()
    }

    const onSave = (value) => {
        if (props.onSave) props.onSave(value)
        handleClose()
    }

    const onCancel = (value) => {
        if (props.onCancel) props.onCancel(value)
        handleClose()
    }

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
        handleClose()
    }

    const handleClose = () => {
        setMoreAnchorEl(null)
        setSelectedMenu(null)
    }

    const getGroupedItems = () => {
        let items = props?.details ?? []
        let groups = []
        items.forEach(i => {
            let hasGroup = groups.filter(g => g.category === i.properties.details.category.value)
            if (hasGroup && hasGroup.length > 0)
                hasGroup[0].items.push(i)
            else {
                groups.push({
                    category: i.properties.details.category.value,
                    items: [i]
                })
            }
        })
        return groups
    }

    const handleCategoryCollapseClick = (category) => {
        let cc = [...categoriesClosed]
        let closedCategories = cc.filter(c => c === category)
        if (closedCategories.length === 0)
            cc.push(category)
        else {
            cc = cc.filter(c => c !== category)
        }
        setCategoriesClosed(cc)
    }

    const openCategoryCollapse = (category) => {
        let cc = [...categoriesClosed]
        cc = cc.filter(c => c !== category)
        setCategoriesClosed(cc)
    }

    return (
        <Paper className={classes.root} elevation={2}>
            {/* <div className={classes.container}> */}
            <div className={classes.title}>
                <Typography variant="h6" className={classes.grow}>
                    {props.title ? props.title : 'Features'}
                </Typography>
                {showFilter &&
                    <TextField
                        color="default"
                        className={classes.filter}
                        value={filter}
                        onChange={handleFilterChange}
                        key={'filter_txt'}
                        margin="dense"
                        id={"filter_txt"}
                        // label={'Filter'}
                        placeholder={'Filter Features'}
                        type="text"
                        fullWidth
                    />
                }

                {!showFilter &&
                    <IconButton
                        className={classes.filterIcon}
                        title='Import boundaries from a file'
                        alt='Import boundaries from a file'
                        onClick={(e) => {
                            if (props.onUpload) props.onUpload()
                        }}>
                        <CloudUpload />
                    </IconButton>}

                <IconButton className={classes.filterIcon}>
                    {!showFilter ?
                        <FilterList onClick={() => {
                            setShowFilter(true)
                            setShowClients(false)
                        }} />
                        : <Close onClick={() => {
                            setShowFilter(false)
                            setShowClients(false)
                            setFilter('')
                        }} />}
                </IconButton>
            </div>
            <Divider />
            {!props.loading ?//|| props.details ?
                <>{(props?.details && props.details.filter(d => { return !filter || filter.length === 0 ? true : d?.properties?.details?.name?.value?.toLowerCase().includes(filter.toLowerCase()) }).length > 0) ?
                    <div className={classes.features}>
                        <List dense={true}>
                            {categories.sort((a,b) => (a.category.toLowerCase() < b.category.toLowerCase()) ? -1 : (a.category.toLowerCase() > b.category.toLowerCase()) ? 1 : 0 ).map(category => {
                                if (category.items.filter(d => { return !filter || filter.length === 0 ? true : d.properties.details.name.value.toLowerCase().includes(filter.toLowerCase()) }).length === 0) {
                                    return <></>
                                }

                                return (<>
                                    <ListItem
                                        button
                                        className={classes.category}
                                        onClick={(e) => handleCategoryCollapseClick(category.category)}>
                                        <ListItemIcon style={{ minWidth: '35px' }} >
                                            <IconButton
                                                className={classes.actionButton}
                                                aria-label="expand"
                                                onClick={(e) => handleCategoryCollapseClick(category.category)}
                                            >
                                                {categoriesClosed.filter(c => c === category.category).length > 0 ?
                                                    <ArrowDropUp />
                                                    : <ArrowDropDown />}
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText primary={category.category + ` (${category.items?.length > 0 ? category.items?.length : '0'})`} />
                                        <ListItemSecondaryAction style={{ right: '0px' }}>
                                            <IconButton
                                                className={classes.actionButton}
                                                aria-label="Show More"
                                                aria-controls={category.category}
                                                aria-haspopup="true"
                                                onClick={(e) => {
                                                    setSelectedMenu(category.category)
                                                    setMoreAnchorEl(e.currentTarget)
                                                }}>
                                                <MoreVert />
                                            </IconButton>
                                            <Menu
                                                id={category.category}
                                                anchorEl={moreAnchorEl}
                                                keepMounted
                                                open={selectedMenu === category.category}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={(e) => onDeleteCategory(category.category)}>
                                                    <ListItemIcon>
                                                        <DeleteIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">Delete {category.category}</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Collapse in={categoriesClosed.filter(c => c === category.category).length === 0} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {category.items.sort((a,b) => (a.properties.details.name.value.toLowerCase() < b.properties.details.name.value.toLowerCase()) ? -1 : (a.properties.details.name.value.toLowerCase() > b.properties.details.name.value.toLowerCase()) ? 1 : 0 ).filter(d => { return !filter || filter.length === 0 ? true : d.properties.details.name.value.toLowerCase().includes(filter.toLowerCase()) }).map(feature => {
                                                return <FeaturesListItem
                                                    key={feature.properties.id + '_item'}
                                                    details={feature}
                                                    selectedFeature={props.selectedFeature}
                                                    onSelect={onSelect}
                                                    onEdit={onEdit}
                                                    onDelete={onDelete}
                                                    onZoom={onZoom}
                                                    onSave={onSave}
                                                    onCancel={onCancel} />
                                            })}
                                        </List>
                                    </Collapse> </>)
                            })}
                        </List>
                    </div>
                    : <div className={classes.noItems}><Typography variant={'caption'}> No features</Typography></div>
                }</> : <div className={classes.center}>
                    <CircularProgress />
                </div>}
            {/* </div> */}
        </Paper>
    );
}
