import React, { useContext, useEffect } from 'react'

import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import { Switch, Route, Redirect } from 'react-router-dom'

import { BrandContext } from '../context/BrandContext/context'
import { SiteContext } from '../context/SiteContext/context'
import { UserContext } from '../context/UserContext/context'

export default function Content() {
    const { settings } = useContext(SiteContext)
    const { brand } = useContext(BrandContext)

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            width: '100%',
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
        },
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            maxWidth: '100%',
        },
        fullHeightContainer: {
            padding: theme.spacing(0),
            maxWidth: '100%',
            height: brand.navbar.show && brand.navbar.position === 'fixed' ? 'calc(100% - 64px)' : '100%',
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
    }))

    const classes = useStyles()
    const { user } = useContext(UserContext)

    useEffect(() => {
        console.log('!!!!!!!!! user context changed in content.js', user)
    }, [user])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <div className={brand.navbar.show && brand.navbar.position === 'fixed' ? classes.appBarSpacer : ''} />
                <Container className={brand.fullHeight ? classes.fullHeightContainer : classes.container}>
                    <Switch>
                        {settings.routes.map((prop, key) => (
                            <Route
                                path={prop.path}
                                component={prop.component}
                                key={key}
                                exact  />
                        ))}
                    </Switch>
                </Container>
            </main>
        </div>
    )
}
