import React from 'react'

import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Search, LocationSearching, LocationDisabled, Clear } from '@material-ui/icons'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 500,
        borderRadius: '25px'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    'pac-container': {
        backgroundColor: 'pink',
    },
}))

export default function GoogleSearchBox(props) {
    const classes = useStyles()

    return (
        <Paper component="form" className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="geolocation">
                {props.disableGeolocation ? <LocationDisabled /> : <LocationSearching />}
            </IconButton>
            <InputBase
                id={props.id ? props.id : 'pac-input'}
                className={classes.input}
                placeholder={props.placeholder}
                inputProps={{ 'aria-label': props.placeholder }}
                // onChange={(event, newValue) => {
                //     this.handleAddressChange(newValue);
                // }}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={props.onClick}>
                <Search />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton className={classes.iconButton} aria-label="clear">
                <Clear />
            </IconButton>
        </Paper>
    )
}
