import React, { useContext } from 'react'

import { Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons'

import Utils from '../../../utils/Utils'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '250px',
    },
    contentRoot: {
        width: '100%',
    },
}))

function Content(props) {
    const classes = useStyles()

    return (
        <div className={classes.contentRoot}>
            <Typography variant="h6">
                {props.details && props.details.tower && props.details.tower.name
                    ? props.details.tower.name
                    : 'No Tower Data to display'}
            </Typography>
            <Typography variant="body1">
                Tower Provider:{' '}
                {props.details &&
                props.details.tower &&
                props.details.tower.attributes &&
                props.details.tower.attributes.provider
                    ? props.details.tower.attributes.provider
                    : '-'}
            </Typography>
            <Divider />
            <Typography variant="body1">
                Tower Height:{' '}
                {props.details && props.details.tower && props.details.tower.height ? props.details.tower.height : 0}m
            </Typography>
            <Divider />
            <Typography variant="body1">
                Distance: {props.details && props.details.distance ? props.details.distance : 0}m
            </Typography>
            <Divider />
            <Typography variant="body1">Heading: {props.degrees ? props.degrees : 0}&deg;</Typography>
            <Divider />
        </div>
    )
}

export default function TowerContent(props) {
    const classes = useStyles()
    const local1 = {
        latitude: props.location.latitude,
        longitude: props.location.longitude,
    }
    const local2 = {
        latitude: props.details.tower.location.lat,
        longitude: props.details.tower.location.lng,
    }
    const degrees = Utils.angleFromCoords(local1.latitude, local2.latitude, local1.longitude, local2.longitude)
    return (
        <div className={classes.root}>
            <Content details={props.details} degrees={degrees} />
        </div>
    )
}
